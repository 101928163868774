import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import withRouter from "helpers/withRouter";
import AddExemption from '../containers/Documents/ExemptionCertificates/AddExemption/AddExemption'
import {
  getIsManager,
  getIsTaxAgentMainStatus,
  isUserTaxAgentOrManager,
  getIsOMP,
} from "../redux/selectors";

import LogIn from "containers/Authentication/screens/LogIn";
import Dashboard from "containers/Dashboard";
import DueDates from "containers/DueDates";
import Profile from "containers/Profile";
import News from "containers/News";
import NewsItem from "containers/News/NewsItem";
import Billing from "containers/Billing";
import Reports from "containers/Reports/Reports";
import TaxSettings from "containers/TaxSettings/TaxSettings";
import OrderRegistration from "containers/VatRegistration/OrderRegistration";
import AddEPRReporting from "containers/EPRReporting/AddEPRReporting";
import EPRReporting from "containers/EPRReporting";
import VATReturns from "containers/VATReturns/VATReturns";
import CreateReturn from "containers/VATReturns/CreateReturn";
import SalesTaxReturns from "containers/SalesTaxReturns/SalesTaxReturns";
import Stores from "containers/Stores/Stores";
import CreateTaxReturn from "containers/SalesTaxReturns/CreateTaxReturn";
import AddStore from "containers/Stores/AddStore";
import Transactions from "containers/Transactions/Transactions";
import AddTransactions from "containers/Transactions/AddTransactions";
import Subscription from "containers/Subscription/Subscription";
import Addons from "containers/Addons/Addons";
import SignUp from "containers/Authentication/screens/SignUp/SignUp";
import ForgotPassword from "containers/Authentication/screens/ForgotPassword/ForgotPassword";
import LogInAdmin from "containers/Authentication/screens/LogInAdmin/LogInAdmin";
import Activation from "containers/Authentication/screens/Activation/Activation";
import AddPayment from "containers/Billing/AddPayment";
import AddInvoice from "containers/Billing/AddInvoice";
import HelpCenter from "containers/HelpCenter/HelpCenter";
import Dialog from "containers/Dialog/Dialog";
import Case from "containers/Case/Case";
import Threshold from "containers/Threshold/Threshold";
import VATPayment from "containers/VATPayment/VATPayment";
import AddNewsItem from "containers/News/AddNewsItem/AddNewsItem";
import Messages from "containers/Messages";
import Message from "containers/Message/Message";
import AddMessage from "containers/AddMessage";
import Clients from "containers/Clients";
import AddClient from "containers/AddClient/AddClient";
import CodeConfirmation from "containers/CodeConfirmation";
import Documents from "containers/Documents/Documents";
import DocumentTemplate from "containers/DocumentTemplate/DocumentTemplate";
import Dac7Report from "containers/Dac7";
import Report1099 from "containers/1099";
import Unsubscribe from "containers/Unsubscribe";
import useIntegrateStripeIfNeeded from "./hooks/useIntegrateStripeIfNeeded";

const RoutesComponent = ({ isLogged, isLoggingIn }) => {
  const state = useSelector((state) => state);
  const IS_TAX_AGENT_MAIN_STATUS = getIsTaxAgentMainStatus(state);
  const IS_MANAGER = getIsManager(state);
  const IS_TAX_AGENT_OR_MANAGER = isUserTaxAgentOrManager(state.user.user);
  const IS_SHOW_VAT_RETURNS_IN_MENU = state.user.user.vat_returns_show_in_menu;
  const IS_SHOW_SALES_TAX_RETURNS_IN_MENU =
    state.user.user.sales_tax_returns_show_in_menu;
  const IS_OMP = getIsOMP(state);
  const IS_DAC7_ENABLED = state.user.user.dac7;

  // console.log(state.user.user)

  useIntegrateStripeIfNeeded(isLogged);

  const commonRoutes = (
    <>
      <Route path='/unsubscribe/:token' exact element={<Unsubscribe />} />
    </>
  );

  const loggedInRoutes = (
    <>
      <Route path='/dashboard' exact element={<Dashboard />} />
      <Route path='/' exact element={<Dashboard />} />
      <Route path='/dashboard/news' exact element={<News />} />
      <Route path='/dashboard/news/:newsId' exact element={<NewsItem />} />
      {IS_MANAGER && (
        <Route path='/dashboard/add-news' exact element={<AddNewsItem />} />
      )}
      <Route path='/dashboard/due-dates' exact element={<DueDates />} />
      <Route path='/dashboard/threshold' exact element={<Threshold />} />
      <Route path='/profile' exact element={<Profile />} />
      <Route path='/billing' exact element={<Billing />} />
      <Route path='/billing/add-payment' exact element={<AddPayment />} />
      <Route path='/billing/add-invoice' exact element={<AddInvoice />} />

      <Route path='/transactions' exact element={<Transactions />} />
      <Route path='/transactions/import' exact element={<AddTransactions />} />
      <Route path='/stores' exact element={<Stores />} />
      <Route path='/stores/add' exact element={<AddStore />} />
      <Route path='/reports' exact element={<Reports />} />
      <Route path='/tax-settings' exact element={<TaxSettings />} />
      <Route path='/tax-settings/order' exact element={<OrderRegistration />} />
      <Route path='/subscription' exact element={<Subscription />} />
      <Route path='/addons' exact element={<Addons />} />
      <Route path='/messages' exact element={<Messages />} />
      <Route path='/messages/add' exact element={<AddMessage />} />
      <Route path='/messages/:messageId' exact element={<Message />} />

      <Route path='/help' exact element={<HelpCenter />} />
      <Route path='/help/messenger/:dialogId' exact element={<Dialog />} />
      <Route path='/help/messenger/case' exact element={<Case />} />
      {IS_SHOW_VAT_RETURNS_IN_MENU && (
        <>
          <Route path='/vat-returns' exact element={<VATReturns />} />
          <Route path='/vat-payment' exact element={<VATPayment />} />
          <Route
            path='/vat-returns/create-return'
            exact
            element={<CreateReturn />}
          />
          <Route
            path='/vat-returns/create-return/:id/:countryId/:countryName/:period/:periodType/:year/:reason/:typeOfReport'
            exact
            element={<CreateReturn />}
          />
        </>
      )}
      {!IS_TAX_AGENT_MAIN_STATUS && IS_SHOW_SALES_TAX_RETURNS_IN_MENU && (
        <>
          <Route path='/tax-returns' exact element={<SalesTaxReturns />} />
          <Route
            path='/tax-returns/create-return'
            exact
            element={<CreateTaxReturn />}
          />
        </>
      )}

      {IS_TAX_AGENT_OR_MANAGER && (
        <>
          <Route path='/clients' exact element={<Clients />} />
          <Route path='/clients/add-client' exact element={<AddClient />} />
        </>
      )}
      <Route path='/epr-reporting' exact element={<EPRReporting />} />
      <Route path="/epr-reporting/add" exact element={<AddEPRReporting />} />
      <Route
        path='/epr-reporting/add/:id/:countryId/:year/:reportType'
        exact
        element={<AddEPRReporting />}
      />

      <Route path='/documents' exact element={<Documents />} />
      <Route
        path='/documents/:type/create'
        exact
        element={<DocumentTemplate />}
      />
      <Route
        path='/documents/exemption/add'
        exact
        element={<AddExemption />}
      />
      {/*<Route*/}
      {/*  path='/documents/exemption/create'*/}
      {/*  exact*/}
      {/*  element={<CreateExemption />}*/}
      {/*/>*/}

      {IS_OMP && IS_DAC7_ENABLED && (
        <Route path='/dac7-reporting' exact element={<Dac7Report />} />
      )}
      {IS_OMP && IS_DAC7_ENABLED && (
        <Route
          path='/dac7-immovable-property'
          exact
          element={<Dac7Report isImmovableProperty={true} />}
        />
      )}

      <Route path='/return-1099' exact element={<Report1099 />} />

      <Route path='*' element={<Navigate to='/profile' />} />
    </>
  );

  const notLoggedInRoutes = (
    <>
      <Route path='/login' exact element={<LogIn />} />
      <Route path='/signup' exact element={<SignUp />} />
      <Route path='/manager' exact element={<LogInAdmin />} />
      <Route path='/forgot' exact element={<ForgotPassword />} />
      <Route path='/forgot/:code' element={<ForgotPassword />} />
      <Route path='/activation/:code' element={<Activation />} />
      <Route path='/confirm-2fa' element={<CodeConfirmation />} />
      {isLoggingIn && <Route path='*' element={<Navigate to='/login' />} />}
    </>
  );

  return (
    <Routes>
      {commonRoutes}
      {isLogged ? loggedInRoutes : notLoggedInRoutes}
    </Routes>
  );
};

export default withRouter(RoutesComponent);
