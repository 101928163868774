import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import Modal from "components/Modal/Modal";
import BannerCustom from '../../components/Banners/BannerCustom'
import HighlightableDataTable from "../../components/HighlightableDataTable/index"
import {
  editBalance, fetchBalanceDashboard, fetchCredits,
  fetchDeemedVat,
  fetchLastUploadedTansation,
  fetchNews,
  fetchTransByCountryOmp
} from '../../redux/features/dashboard/dashboardSlice'
import {
  fetchDeclarations,
  fetchDeclarationsSalesTax,
  fetchEprDeclarations
} from '../../redux/features/declarations/declarationsSlice'
import { fetchDueDates } from '../../redux/features/dueDates/dueDatesSlice'
import { fetchPaymentInfo } from '../../redux/features/profile/profileSlice'
import { fetchBonusProgram } from '../../redux/features/tax/taxSlice'
import { fetchThresholdEU, fetchThresholdUS } from '../../redux/features/thresholds/thresholdsSlice'
import { fetchCompanyDefaultLanguage } from '../../redux/features/user/userSlice'
import { isTaxAgentSelector } from "../../redux/selectors";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import PageHelmet from "components/PageHelmet";
import UserGuide from "components/UserGuide/UserGuide";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import withRouter from "helpers/withRouter";
import { formatDate, formatTime } from "utils/dates";
import { getCurrencySymbol } from "utils/currency";
import { formatMoney } from "utils/numbers";
import {
  Page,
  Card,
  Button,
  Badge,
  Spinner,
  Scrollable,
  ResourceList,
  ResourceItem,
  TextField,
  ButtonGroup,
  Banner,
  DataTable,
  Pagination,
  Text,
  Box,
} from "@shopify/polaris";
import formatRow from "utils/formatRow";

import { AddIcon, AlertIcon } from "icons";
import CardImg from "img/card.svg";
import { ReactComponent as CalendarImg } from "img/calendar-img.svg";
import { ReactComponent as ReminderImg } from "img/reminder-img.svg";
import { ReactComponent as FinanceImg } from "img/finance-img.svg";

import "./style.scss";
import { createCustomDispatch } from "helpers/customDispatch";

const defaultLimitDecl = 3;
const defaulLimitDueDates = 4;
const defaultLimitNews = 4;
const defaulLimitThresholdUS = 10;
const defaultLimitTransByCountryOmp = 10;
const chartSeriesColors = ["#9c6ade", "#59c7c5", "#0090f0", "#5C6AC4"];

ChartJS.register(...registerables);

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStateInd: 0,
      actionDialogs: {
        subscription: { open: false },
        contactSupport: { open: false },
      },
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.is_tax_agent || (user.is_manager && user.company.is_tax_agent)) {
      this.props.fetchBonusProgram();
    } else {
      this.props.fetchDueDates({ limit: defaulLimitDueDates });
    }

    if (user.is_omp) {
      this.props.fetchDeemedVat();
      this.props.fetchTransByCountryOmp({
        limit: defaultLimitTransByCountryOmp,
      });
    }

    this.props.fetchLastUploadedTansation();
    this.props.fetchNews({ limit: defaultLimitNews });
    this.props.fetchDeclarations({ limit: defaultLimitDecl });
    this.props.fetchDeclarationsSalesTax({ limit: defaultLimitDecl });
    this.props.fetchEprDeclarations({ limit: defaultLimitDecl });
    this.props.fetchThresholdEU({ year: new Date().getFullYear() });
    this.props.fetchCredits();
    this.props.fetchThresholdUS({
      year: new Date().getFullYear(),
      limit: defaulLimitThresholdUS,
    });
    this.props.fetchPaymentInfo();
    
    this.props
      .fetchBalanceDashboard()
      .then(() => {
        this.setState({ balance: this.props.balance?.amount || 0 });
      })
      .catch(_.noop);
  }

  getPeriodTypeForVat = (value) => {
    const { t } = this.props;
    switch (value) {
      case "year":
        return t("dash.yVAT");
      case "quarter":
        return t("dash.qVAT");
      case "month":
        return t("dash.mVAT");
      case "bi-annual":
        return "Bi-Annual VAT";
      default:
        return value;
    }
  };

  getPeriodTypeForEPR = (value) => {
    const { t } = this.props;
    switch (value) {
      case "year":
        return t("dash.yEPR");
      case "quarter":
        return t("dash.qEPR");
      case "month":
        return t("dash.mEPR");
      default:
        return value;
    }
  };

  getPeriodTypeForSalesTax = (value) => {
    const { t } = this.props;
    switch (value) {
      case "year":
        return t("dash.ySalexTax");
      case "quarter":
        return t("dash.qSalesTax");
      case "month":
        return t("dash.mSalesTax");
      case "bi-annual":
        return "Bi-Annual Sales tax";
      default:
        return value;
    }
  };
  
  getStatusForVat = (value) => {
    const { t } = this.props;
    let style = {
      display: "inline-block",
      padding: "0px 6px",
      borderRadius: "16px",
      fontWeight: "400",
      fontSize: "12px",
    };
    
    let textColor = "rgba(71, 71, 71, 1)";
    
    switch (value) {
      case "new":
        style = { ...style, backgroundColor: "rgba(228, 243, 254, 1)" };
        textColor = "rgba(38, 127, 215, 1)";
        break;
      case "submitted":
      case "auto-submitted":
        style = { ...style, backgroundColor: "rgba(200, 230, 205, 1)" };
        textColor = "rgba(62, 141, 75, 1)";
        break;
      case "declined":
        style = { ...style, backgroundColor: "rgba(255, 238, 178, 1)" };
        textColor = "rgba(184, 130, 27, 1)";
        break;
      case "draft":
        style = { ...style, backgroundColor: "#F4F6F8" };
        textColor = "#637381";
        break;
      case "pending":
        style = { ...style, backgroundColor: "rgba(254, 228, 226, 1)" };
        textColor = "rgba(180, 35, 24, 1)";
        break;
      default:
        style = { ...style, backgroundColor: "rgba(194, 194, 194, 1)" };
        textColor = "rgba(71, 71, 71, 1)";
        break;
    }
    
    return (
      <div style={style}>
      <span style={{ color: textColor }}>{t(`dash.statuses.${value}`)}</span>
    </div>
    );
  };
  
  
  
  doEditBalance = () => {
    this.props
      .editBalance({ amount: this.state.balance })
      .then(() => {
        this.props.fetchBalanceDashboard();
        this.setState({
          editing: false,
          editingError: false,
        });
      })
      .catch(() => {
        this.setState({ editingError: "Error" });
      });
  };

  createMatrix = (m, n) => {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(new Array(m).fill(null));
    }
    return result;
  };

  handleActionDialogsOpen(name, data = {}) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  }

  handleActionDialogsClose(name) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  }

  beforeDraw = function (
    chart,
    percentFact,
    thresholdFactSum,
    thresholdSum,
    currency = "$"
  ) {
    const width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
    ctx.restore();
    ctx.font = "bold 24px sans-serif";
    ctx.textBaseline = "center";
    ctx.fillStyle = "#2E2E2E";
    const text = `${percentFact}%`,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;
    ctx.fillText(text, textX, textY);
    ctx.save();
    ctx.font = "14px sans-serif";
    ctx.fillStyle = "#707070";
    const text2 = `${currency}${thresholdFactSum} / ${currency}${thresholdSum}`,
      textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
      textY2 = height / 1.55;
    ctx.fillText(text2, textX2, textY2);
    ctx.save();
  };

  renderSubscriptionDialog = () => {
    const { t } = this.props;
    const { actionDialogs } = this.state;

    return (
      <Modal
        title={t("dash.warning")}
        visible={actionDialogs.subscription.open}
        onClose={() => {
          this.handleActionDialogsClose("subscription");
        }}
        content={
          <p>
            {t("dash.overPlan")} (
            {
              <Link className='Polaris-Link' to='/subscription'>
                {t("dash.selectPlan")}
              </Link>
            }
            ){t("dash.dot")}
            <br />
            {t("dash.canCont")}
            {t("dash.dot")}
          </p>
        }
      />
    );
  };

  sortRowsByStatus = (rows) => {
    const statusOrder = { red: 1, yellow: 2 };
    return rows.slice().sort((a, b) => {
      const statusA = a.status.toLowerCase();
      const statusB = b.status.toLowerCase();
      return (
        statusOrder[statusA] - statusOrder[statusB] ||
        statusA.localeCompare(statusB)
      );
    });
  };

  renderDueDates = () => {
    const { t, dueDates, fetchingDueDates, user } = this.props;
    const IS_TAX_AGENT = isTaxAgentSelector(user);
    const rows = this.sortRowsByStatus(dueDates.items ?? []);

    const columns = [
      {
        property: "country",
      },
      {
        property: "period_type",
        cell: {
          formatters: [
            (value, { rowData }) => {
              if (rowData.type_of_report.includes("Sales Tax")) {
                return this.getPeriodTypeForSalesTax(rowData.period_type);
              } else if (rowData.type_of_report.includes("EPR")) {
                return this.getPeriodTypeForEPR(rowData.period_type);
              }

              return this.getPeriodTypeForVat(rowData.period_type);
            },
          ],
        },
      },
      {
        cell: {
          formatters: [
            (value, { rowData }) =>
              `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
          ],
        },
      },
      {
        property: "exp_date",
      },
      {
        property: "",
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                (rowData.status === "red" || rowData.status === "yellow") && (
                  <AlertIcon
                    stroke={rowData.status === "red" ? "#F97066" : "#B8821B"}
                  />
                )
              );
            },
          ],
        },
      },
      {
        property: "",
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <Box alignItems='end'>
                  {!IS_TAX_AGENT ? (
                      <div
                        style={{height: '32px', background: 'rgba(38, 127, 215, 1)', color: '#fff', borderColor: 'transparent', boxShadow: 'none', borderRadius: 6, width: 93, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', fontSize: 12}}
                        
                        onClick={() => {
                          if (rowData.type_of_report.includes("VAT return")) {
                            this.props.navigate("/vat-returns");
                          } else {
                            this.props.navigate("/tax-returns");
                          }
                        }}
                      >
                      {t("dash.fillVR")}
                    </div>
                    
                  ) : (
                    <Text>{rowData.company_name}</Text>
                  )}
                </Box>
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);

    return (
      <Card
        sectioned={fetchingDueDates || _.isEmpty(rows)}
      >
        {fetchingDueDates ? (
          <Spinner />
        ) : (
          <React.Fragment >
            <div style={{display: "flex", justifyContent: "space-between", alignItems: 'flex-start'}}>
              <h2 style={{fontSize: 18, fontWeight: '600'}}>{t("dash.title.yourDD")}</h2 >
              <button
                style={{
                  maxHeight: '20px',
                  borderColor: 'transparent',
                  boxShadow: 'none',
                  background: 'transparent',
                  height: 'auto',
                  color: 'rgba(44, 110, 203, 1)',
                  cursor: 'pointer',
                  fontSize: 14,
                  fontWeight: 400,
                }}
                onClick={() => this.props.navigate("/dashboard/due-dates")}
              >
                {t("dash.viewAll")}
              </button >
            </div >
            {!_.isEmpty(rows) && !fetchingDueDates && (
              <>
                <br />
                <div className='border-top'></div >
                <HighlightableDataTable
                  headings={[]}
                  rows={sortedRows}
                  rawItems={rows}
                  rowClassNameCallback={(row,
                    rowData) => ({
                    "bg-red": rowData.status === "red",
                    "bg-yellow": rowData.status === "yellow",
                  })}
                  styles={{
                    td: {
                      padding: "0.3rem 0.85rem",
                      fontSize: 14,
                      fontWeight: '400',
                    },
                  }}
                />
              </>
            )}
            
            {_.isEmpty(rows) && !fetchingDueDates && (
              <div >
                <NoDataMessage
                  title={"Nothing to show here yet"}
                  description={"Add store to see data"}
                  buttonText={"Add store"}
                  Image={CalendarImg}
                  style={{height: "auto"}}
                  onClick={() => this.props.navigate("/stores/add")}
                />
              </div >
            )}
          </React.Fragment >
        )}
      </Card >
    );
  };
  
  renderVatReturns = () => {
    const {t, declarations, fetchingDeclarations} = this.props;
    const rows = declarations ? declarations.items : [];
    
    return (
      <Card
        sectioned={fetchingDeclarations || _.isEmpty(rows)}
      >
        {fetchingDeclarations ? (
          <Spinner />
        ) : (
          <React.Fragment >
            <div style={{display: "flex", justifyContent: "space-between", alignItems: 'flex-start'}}>
              <h2 style={{fontSize: 18, fontWeight: '600'}}>{t("dash.title.vatRet")}</h2 >
              <button
                style={{
                  maxHeight: '20px',
                  borderColor: 'transparent',
                  boxShadow: 'none',
                  background: 'transparent',
                  height: 'auto',
                  color: 'rgba(44, 110, 203, 1)',
                  fontSize: 14,
                  fontWeight: 400,
                  cursor: 'pointer'
              }} onClick={() => this.props.navigate("/vat-returns")}>
                {t("dash.viewAll")}
              </button>
            </div >
            
            {!_.isEmpty(rows) > 0 && !fetchingDeclarations && (
              <>
                <br />
                <div className='border-top table-dashboard-returns'>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "numeric",
                    ]}
                    headings={[]}
                    rows={[...rows].map((rowData) => [
                      rowData.customer_country.name,
                      this.getPeriodTypeForVat(rowData.period_type),
                      `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
                      this.getStatusForVat(rowData.status),
                    ])}
                  />
                </div>
              </>
            )}
            {_.isEmpty(rows) && !fetchingDeclarations && (
              <div >
                <NoDataMessage
                  title={"Nothing to show here yet"}
                  description={"Add VAT returns to see data"}
                  buttonText={
                    this.props.user.vat_returns_show_in_menu
                      ? "Add VAT returns"
                      : null
                  }
                  Image={ReminderImg}
                  style={{height: "auto" }}
                  onClick={() =>
                    this.props.navigate("/vat-returns/create-return")
                  }
                />
              </div>
            )}
          </React.Fragment>
        )}
        </Card>
    );
  };

  renderSalesTaxReturns = () => {
    const { t, declarationsSalesTax, fetchDeclarationsSalesTax, fetchingSalesTax } = this.props;
    const rows = declarationsSalesTax ? declarationsSalesTax.items : [];
    return (
      <Card
        sectioned={fetchDeclarationsSalesTax || _.isEmpty(rows)}
      >
        {fetchingSalesTax ? (
          <Spinner />
        ) : (
          <React.Fragment >
            <div style={{display: "flex", justifyContent: "space-between", alignItems: 'flex-start'}}>
              <h2 style={{fontSize: 18, fontWeight: '600'}}>{t("dash.title.salesTaxRet")}</h2 >
              <button
                style={{
                  maxHeight: '20px',
                  borderColor: 'transparent',
                  boxShadow: 'none',
                  background: 'transparent',
                  height: 'auto',
                  color: 'rgba(44, 110, 203, 1)',
                  fontSize: 14,
                  fontWeight: 400,
                  cursor: 'pointer'
                }}
                onClick={() => this.props.navigate("/tax-returns")}
              >
                {t("dash.viewAll")}
              </button >
            </div >
            {!_.isEmpty(rows) > 0 && !fetchingSalesTax && (
              <>
                <br />
                <div className='border-top table-dashboard-returns'>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "numeric",
                    ]}
                    headings={[]}
                    rows={[...rows].map((rowData) => [
                      rowData.state.name,
                      this.getPeriodTypeForVat(rowData.period_type),
                      `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
                      this.getStatusForVat(rowData.status),
                    ])}
                  />
                </div >
              </>
            )}
            {_.isEmpty(rows) && !fetchingSalesTax && (
              <div >
                <NoDataMessage
                  title={"Nothing to show here yet"}
                  description={"Add TAX returns to see data"}
                  buttonText={
                    this.props.user.vat_returns_show_in_menu
                      ? "Add TAX returns"
                      : null
                  }
                  Image={ReminderImg}
                  style={{height: "auto"}}
                  onClick={() =>
                    this.props.navigate("/tax-returns/create-return")
                  }
                />
              </div >
            )}
          </React.Fragment >
        )}
        </Card >
    );
  };
  
  
  renderEPRReturns = () => {
    const {t, declarationsEpr, fetchEprDeclarations, fetchingEPR} = this.props;
    const rows = declarationsEpr ? declarationsEpr.items : [];
    // console.log( declarationsEpr)
    return (
      <Card
        title={t("dash.title.eprRet")}
        actions={[
          {
            content: t("dash.viewAll"),
            onClick: () => this.props.navigate("/epr-reporting"),
          },
        ]}
        sectioned={fetchEprDeclarations || _.isEmpty(rows)}
      >
        {fetchingEPR ? (
          <Spinner />
        ) : (
          <React.Fragment >
            <div style={{display: "flex", justifyContent: "space-between", alignItems: 'flex-start'}}>
              <h2 style={{fontSize: 18, fontWeight: '600'}}>{t("dash.title.eprRet")}</h2 >
              <button
                style={{
                  maxHeight: '20px',
                  borderColor: 'transparent',
                  boxShadow: 'none',
                  background: 'transparent',
                  height: 'auto',
                  color: 'rgba(44, 110, 203, 1)',
                  cursor: 'pointer',
                  fontSize: 14,
                  fontWeight: 400,
                }}
                onClick={() => this.props.navigate("/epr-reporting")}
              >
                {t("dash.viewAll")}
              </button >
            </div >
            {!_.isEmpty(rows) > 0 && !fetchingEPR && (
              <>
                <br />
                <div className='border-top table-dashboard-returns'>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "numeric",
                    ]}
                    headings={[]}
                    rows={[...rows].map((rowData) => [
                      rowData.name,
                      this.getPeriodTypeForVat(rowData.period_type),
                      `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
                      this.getStatusForVat(rowData.status),
                    ])}
                  />
                </div >
              </>
            )}
            {_.isEmpty(rows) && !fetchingEPR && (
              <div >
                <NoDataMessage
                  title={"Nothing to show here yet"}
                  description={"Add EPR returns to see data"}
                  buttonText={
                    this.props.user.vat_returns_show_in_menu
                      ? "Add EPR return"
                      : null
                  }
                  // Image={ReminderImg}
                  style={{height: "auto"}}
                  onClick={() =>
                    this.props.navigate("/epr-reporting/add")
                  }
                />
              </div >
            )}
          </React.Fragment >
        )}
        </Card >
    );
  };
  
  renderNews = () => {
    const {t, fetchingNews} = this.props;
    const {news} = this.props || [];
    
    return (
      <div
        style={{background: '#fff', borderRadius: 8, width: '100%', border: '1px solid rgba(227, 227, 227, 1)'}}
      >
        <div style={{background: '#fff', borderTopLeftRadius: 8, borderTopRightRadius: 8, borderBottom: '1px solid' +
            ' rgba(227, 227, 227, 1)', marginBottom: 16, padding: '0 24px', height: 54, display: 'flex', alignItems: 'center', width: '100%'}}>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>
              <h2 style={{fontWeight: 'bold', fontSize: 18}}>
                 {t("dash.title.news")}
              </h2 >
              <button
                style={{
                  background: '#fff',
                  borderColor: 'transparent',
                  color: '#267FD7',
                  cursor: 'pointer',
                  fontSize: 14,
                  fontWeight: 400,
                }}
                onClick={() => this.props.navigate("/dashboard/news")}
              >
                {/*{t("dash.title.news")}*/}
                View all
              </button >
            </div >
        </div>
          {fetchingNews && <Spinner />}
          {!fetchingNews && !_.isEmpty(news) && (
            <>
            {news.map((newsItem) => (
              <div key={newsItem.id} style={{padding: '16px 24px', borderBottom: '1px solid rgba(227, 227, 227, 1)'}}>
                <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                  <div >
                    {newsItem?.imageSrc && (
                      <img
                        alt='news'
                        width='180px'
                        height='100%'
                        style={{
                          borderRadius: "8px",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        src={newsItem?.imageSrc || ""}
                      />
                    )}
                  </div >
                  <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <div
                      style={{
                        display: 'flex', flexDirection: 'column', width: '100%',
                      }}
                    >
                      <Text
                        variant='bodyMd'
                        color='subdued'
                      >
                        <span style={{fontSize: 14, color: 'rgba(112, 112, 112, 1)', fontWeight: 400}}>{formatDate(newsItem.date)}</span>
                      </Text >
                      <h2
                        style={{fontSize: 16, fontWeight: '600', lineHeight: '1.2', margin: '10px 0'}}
                      >{newsItem.title}
                      </h2 >
                      <Text >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: newsItem.description,
                          }}
                          style={{fontSize: 14, fontWeight: 400, lineHeight: '18px'}}
                        />
                      </Text >
                      <Link
                        className='Polaris-Link'
                        to={`/dashboard/news/${newsItem.id}`}
                        style={{textDecoration: "none", marginTop: 10}}
                      >
                        Read more
                      </Link >
                    </div >
                  
                  </div >
                </div >
              </div >
            ))}
          </>
          )}
          {!fetchingNews && _.isEmpty(news) && (
            <div >
            <NoDataMessage
              title={"No articles yet"}
              Image={FinanceImg}
              style={{height: "auto"}}
            />
          </div >
          )}
      </div >
  );
  };
  
  renderUserGuide = () => {
    return (
      <Card title={"User Guide"}>
        <div >
          {/*<Banner*/}
          {/*  title={"in development"}*/}
          {/*  tone='critical'*/}
          {/*/>*/}
          <BannerCustom
            status={'critical'}
            message={"in development"}
          />
        </div >
      </Card >
    );
  };
  
  renderThresholdUSChart = () => {
    const {activeStateInd} = this.state;
    const thresholdUS = this.props.thresholdUS
      ? this.props.thresholdUS.items
      : [];
    const labelsForData = ["", ...thresholdUS.map((item) => item.state)];
    const dataForSeries = [100, ...thresholdUS.map((item) => item.percent)];
    const data = {
      labels: labelsForData,
      series: [dataForSeries],
    };
    const total = 100;
    const thresholdFactSum = thresholdUS[activeStateInd].fact_sum;
    const thresholdSum = Math.round(thresholdUS[activeStateInd].threshold_sum);
    const percentFact = thresholdUS[activeStateInd].percent;

    const options = {
      width: "100%",
      height: thresholdUS.length * 25,
      horizontalBars: true,
      reverseData: true,
      chartPadding: {
        bottom: 25,
        right: 35,
      },
      axisX: {
        position: "start",
        labelInterpolationFnc: (value) => `${value.toFixed()}%`,
      },
      axisY: {
        offset: 100,
      },
    };
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            label={
              <Text as="h6" variant="headingMd"  fontWeight="bold">
                {thresholdUS[activeStateInd].state}
              </Text>
            }
            hasPrevious
            onPrevious={() =>
              this.setState({
                activeStateInd:
                  activeStateInd > 0
                    ? activeStateInd - 1
                    : thresholdUS.length - 1,
              })
            }
            hasNext
            onNext={() =>
              this.setState({
                activeStateInd:
                  activeStateInd < thresholdUS.length - 1
                    ? activeStateInd + 1
                    : 0,
              })
            }
          />
        </div>
        <br />
        <div
          key={thresholdUS[activeStateInd].state_code}
          className='dash-chart-container'
        >
          <Doughnut
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return `${context.formattedValue}%`;
                    },
                  },
                },
              },
            }}
            data={{
              datasets: [
                {
                  data: [
                    thresholdUS[activeStateInd].percent,
                    total - (thresholdUS[activeStateInd].percent || 0),
                  ],
                  radius: "100%",
                  cutout: 70,
                  backgroundColor: ["#59C7C5", "#DAF1F2"],
                  borderColor: ["#59C7C5", "#DAF1F2"],
                  hoverBackgroundColor: ["#59C7C5", "#DAF1F2"],
                },
              ],
            }}
            plugins={[
              {
                beforeDraw: (chart) =>
                  this.beforeDraw(
                    chart,
                    percentFact,
                    thresholdFactSum,
                    thresholdSum,
                    "$"
                  ),
              },
            ]}
          />
        </div>
      </div>
    );
  };

  renderThresholdUS = () => {
    const thresholdUS = this.props.thresholdUS
      ? this.props.thresholdUS.items
      : [];
    const { t, fetchingThresholdUS } = this.props;
    const message = thresholdUS ? thresholdUS.message : null;
    const today = new Date();

    return (
      <div>
        {fetchingThresholdUS ? (
          <Spinner />
        ) : (
          <div style={{background: '#fff', borderRadius: 8, padding: 20, border: '1px solid rgba(227, 227, 227, 1)'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <h2 style={{fontWeight: 'bold', fontSize: 18}}>
              Threshold USA
            </h2 >
              <button
                style={{
                  background: '#fff',
                  borderColor: 'transparent',
                  color: 'rgba(44, 110, 203, 1)',
                  cursor: 'pointer',
                  fontSize: 14,
                  fontWeight: 400,
                }}
                onClick={() => this.props.navigate("/dashboard/threshold")}
              >{t("dash.viewAll")}</button >
            </div >
            
            {_.isEmpty(thresholdUS) ? (
              <Text >No data</Text >
            ) : (
              this.renderThresholdUSChart()
            )}
            {message && _.isEmpty(thresholdUS) && <Text >{message}</Text >}
          </div >
        )}
      </div >
    );
  };
  
  renderThresholdEU = () => {
    const { t, thresholdEU, fetchingThresholdEU } = this.props;
    const total = 100;
    return (
      <div style={{background: '#fff', borderRadius: 8, marginTop: 20, padding: 20, border: '1px solid rgba(227, 227, 227, 1)'}}>
      {fetchingThresholdEU ? (
        <Spinner />
      ) : (
        <>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <h2 style={{fontWeight: 'bold', fontSize: 18}}>
              Threshold EU
            </h2 >
              <button
                style={{
                  background: '#fff',
                  borderColor: 'transparent',
                  color: 'rgba(44, 110, 203, 1)',
                  cursor: 'pointer',
                  fontSize: 14,
                  fontWeight: 400,
                }}
                onClick={() => this.props.navigate("/dashboard/threshold")}
              >{t("dash.viewAll")}</button >
            </div >
          <div className='dash-chart-container'>
            <Doughnut
              options={{
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return `${context.formattedValue}%`;
                      },
                    },
                  },
                },
              }}
              data={{
                datasets: [
                  {
                    data: [
                      thresholdEU.percent,
                      total - (thresholdEU.percent || 0),
                    ],
                    radius: "100%",
                    cutout: 70,
                    backgroundColor: ["#59C7C5", "#DAF1F2"],
                    borderColor: ["#59C7C5", "#DAF1F2"],
                    hoverBackgroundColor: ["#59C7C5", "#DAF1F2"],
                  },
                ],
              }}
              plugins={[
                {
                  beforeDraw: (chart) =>
                    this.beforeDraw(
                      chart,
                      thresholdEU.percent,
                      thresholdEU.sum,
                      Math.round(thresholdEU.threshold_sum),
                      "€"
                    ),
                },
              ]}
            />
        </div >
        </>
      )}
    </div >
    );
  };
  
  renderDeemedVat = () => {
    const {deemedVat, fetchingDeemedVat} = this.props;
    
    const data = {
      labels: deemedVat.map((item) => item.month_name),
      datasets: [
        {
          label: "Deemed Import",
          data: deemedVat.map((item) => item.deemed_import),
          backgroundColor: chartSeriesColors[0],
        },
        {
          label: "Deemed EUR",
          data: deemedVat.map((item) => item.deemed_eu),
          backgroundColor: chartSeriesColors[1],
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          align: "center",
        },
      },
      scales: {
        x: {
          position: "bottom",
        },
        y: {
          beginAtZero: true,
          offset: true,
          grace: "10%",
        },
      },
    };

    return (
      <Card sectioned title={"Deemed VAT in thousand EUR"}>
        {fetchingDeemedVat ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {!_.isEmpty(deemedVat) ? (
              <div style={{ width: "100%", height: "250px" }}>
                <Bar data={data} options={options} />
              </div>
            ) : (
              <p style={{ padding: "2rem" }}>No data</p>
            )}
          </React.Fragment>
        )}
      </Card>
    );
  };

  renderTransactionsByCountryOmp = () => {
    const { transByCountry, fetchingTransByCountry } = this.props;
    const rows = transByCountry ? [...transByCountry.items].reverse() : [];

    const data = {
      labels: rows.map((item) => item.country),
      datasets: [
        {
          data: rows.map((item) => item.amount),
          backgroundColor: chartSeriesColors[0],
        },
      ],
    };

    const options = {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          position: "bottom",
          ticks: {
            callback: (value) => `${value}%`,
          },
        },
        y: {
          reverse: true,
        },
      },
    };

    return (
      <Card
        title={"Transactions by country"}
        sectioned={fetchingTransByCountry}
      >
        {fetchingTransByCountry ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {!_.isEmpty(rows) ? (
              <div
                style={{
                  width: "100%",
                  height: `${100 + rows.length * 30}px`,
                  padding: "1rem",
                }}
              >
                <Bar data={data} options={options} />
              </div>
            ) : (
              <p style={{ padding: "2rem" }}>No data</p>
            )}
          </React.Fragment>
        )}
      </Card>
    );
  };

  renderPaymentInfoBlock = (type, sub) => {
    const { t, currentPlan } = this.props;
    
    const getDataForType = () => {
      switch (type) {
        case "vat":
          return {
            sum: currentPlan.subscriptions?.sum,
            nextBillDate: currentPlan.your_next_billing_date,
            title: t("dash.vatSub"),
          };
        case "sales tax":
          return {
            sum: currentPlan?.subscriptions_sales_tax?.sum,
            nextBillDate: currentPlan.your_next_billing_date_sales_tax,
            title: t("dash.taxSub"),
          };
        case "omp":
          return {
            sum: currentPlan?.subscriptions_omp?.sum,
            nextBillDate: currentPlan.your_next_billing_date_omp,
            title: "OMP subscription",
          };

        default:
          return {};
      }
    };

    const { title, nextBillDate, sum } = getDataForType(type);

    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
        <Text
          as='h6'
          variant='headingMd'
          fontWeight='bold'
        >
          {title}
        </Text >
          <div
            style={{display: 'flex', flexDirection: 'row', alignItems: 'end' }}
          >
            <Badge >
              {sub.tariff_plan.name} {t("dash.plan")}
            </Badge >{" "}
            -{" "}
            <Text color='subdued'>
              {formatMoney(sum)}
              {getCurrencySymbol(currentPlan.currency_code)}/ {t("dash.month")}
            </Text >
          </div >
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Text >{t("dash.nextBilling")} - </Text >
            <Text color='subdued'>{formatDate(nextBillDate)}</Text >
          </div >
      </div >
    );
  };
  
  renderEprPaymentInfo(sub) {
    const {t} = this.props;
    const {types, name, your_next_billing_date } = sub;
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
          <Text as="h6" variant="headingMd"  fontWeight="bold">EPR subscription</Text>
          <div
            style={{display: 'flex', flexDirection: 'column', alignItems: 'start' }}
          >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
              <Badge >{name}</Badge >{" "}
              -{" "}
              <Text color='subdued'>
                {types}{" "}
                {types > 1 ? "types" : "type"}
              </Text >
            </div >
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Text>{t("dash.nextBilling")} - </Text>
              <Text color='subdued'>{formatDate(your_next_billing_date)}</Text>
            </div>
          </div>
        </div>
    );
  }

  renderTaxAgentClients = () => {
    const { paymentInfo } = this.props;
    return (
      <Scrollable shadow className='Scrollable'>
        <br />
        {!_.isEmpty(paymentInfo) ? (
          <ResourceList
            items={paymentInfo}
            renderItem={(item, index) => (
              <ResourceItem
                key={`${index}-row`}
                id={index}
                accessibilityLabel={item.company}
              >
                <h3>
                  <Text as="h6" variant="headingMd"  fontWeight="bold">{item.company}</Text>
                </h3>
                <div>Subscription Plan: {item.subs}</div>
                {item.next_billing_date && (
                  <div>{formatDate(item.next_billing_date)}</div>
                )}
              </ResourceItem>
            )}
          />
        ) : (
          <p style={{ padding: "2rem " }}>You don't have any client.</p>
        )}
      </Scrollable>
    );
  };

  renderRemittedSalesTax = () => {
    return (
      <Card title={"Remitted sales tax by states"} sectioned>
        There is not a single sale
      </Card>
    );
  };

  render() {
    const {
      user,
      currentPlan,
      lastUploaded,
      fetchingPaymentInfo,
      thresholdUS,
      thresholdEU,
      bonusProgram,
      fetchingBonus,
      editingBalance,
      t,
      credits,
    } = this.props;

    const { balance, editing, editingError } = this.state;

    const IS_TAX_AGENT = isTaxAgentSelector(user);

    return _.isEmpty(user) ? (
      <div style={{ padding: "1rem" }}>
        <Spinner />
      </div>
    ) : (
      <Page
        fullWidth
        title={
          <Text variant='heading3xl' as='span'>
            {t("dash.hello")}, {user.first_name || ""}
          </Text>
        }
        subtitle={
          user.company &&
          user.company.id !== user.tax_agent_company_id &&
          !(user.is_manager && user.company.is_tax_agent) && (
            <Text variant='bodyLg' as='span' color='subdued'>{user.is_manager.name}
              {t("dash.how")}
            </Text>
          )
        }
        primaryAction={
          <Box display="flex" alignItems="center" gap="6">
            {lastUploaded && lastUploaded[0] && (
              <Text color='subdued'>
                <div style={{ textAlign: "right" }}>
                  {t("dash.lastTransUp")} <br />
                  {lastUploaded[0] &&
                    `${formatDate(
                      lastUploaded[0].transactions_date_update
                    )} ${formatTime(lastUploaded[0].transactions_date_update)}`}
                </div>
              </Text>
            )}

            {!IS_TAX_AGENT &&
              user.company.id !== user.tax_agent_company_id &&
              !user.is_omp && (
                <div style={{width: '191px'}}>
                  <Button
                    icon={AddIcon}
                    outline
                    size='large'
                    fullWidth
                    onClick={() => {
                      if (
                        (!user.is_manager &&
                          !user.is_omp &&
                          currentPlan &&
                          !currentPlan.is_active &&
                          !currentPlan.is_active_25 &&
                          !currentPlan.is_active_sales_tax) ||
                        (!user.is_manager && !user.is_omp && !currentPlan)
                      ) {
                        this.handleActionDialogsOpen("subscription");
                      } else {
                        this.props.navigate("/transactions/import");
                      }
                    }}
                    >
                      {t("dash.addTrans")}
                  </Button>
                </div>
              )}
          </Box>
        }
      >
        <PageHelmet title='Dashboard' />

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
          <div style={{display: 'flex', flexDirection: 'column', flex: '1 1 60%', minWidth: '500px'}}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: '100%',
              }}
            >
              <div
                className='sales-tax-returns'
                style={{margin: 0}}
              >
              {user.is_omp && this.renderDeemedVat()}
              </div >
              
              {!user.company.is_tax_agent &&
                <div
                className='sales-tax-returns'
                style={{marginTop: 0}}
              >
                  {this.renderDueDates()}
                </div>
              }
              
              {!(user.is_manager && user.company.is_tax_agent) &&
                user.company.id !== user.tax_agent_company_id &&
                !user.is_omp && currentPlan?.subscriptions &&
                <div className='sales-tax-returns'>{this.renderVatReturns()}</div>}
              
                {!(user.is_manager && user.company.is_tax_agent) &&
                  user.company.id !== user.tax_agent_company_id &&
                  !user.is_omp && currentPlan?.subscriptions_sales_tax &&
                  <div className='sales-tax-returns'>{this.renderSalesTaxReturns()}</div>}
              
              
                {!(user.is_manager && user.company.is_tax_agent) &&
                  user.company.id !== user.tax_agent_company_id &&
                  !user.is_omp && currentPlan?.subscriptions_epr &&
                  <div className='epr-returns'>{this.renderEPRReturns()}</div>}
                
                {user.is_omp && <div
                  className='sales-tax-returns'
                  style={{marginTop: 0}}
                >{this.renderRemittedSalesTax()}</div>}
              
                {this.renderNews()}
              
            </div >
          </div >
          <div style={{flex: '1 1 25%', minWidth: '300px'}}>
            {(balance || user?.is_manager || !_.isEmpty(credits)) && (
              <div className='block-white'>
                {(balance || user?.is_manager) && (
                  <div>
                    <div>
                      {editingError && (
                        <React.Fragment>
                          {/*<Banner title={editingError} tone='critical' />*/}
                          <BannerCustom
                            status={'critical'}
                            message={editingError}
                          />
                          <br />
                        </React.Fragment>
                      )}
                      {editing ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                          <TextField
                            type='number'
                            value={balance}
                            onChange={(v) => this.setState({ balance: v })}
                          />
                          {editing && (
                            <div>
                              <ButtonGroup>
                                <Button
                                  variant='primary'
                                  loading={editingBalance}
                                  onClick={this.doEditBalance}
                                >
                                  Save
                                </Button>
                                <Button
                                  disabled={editingBalance}
                                  onClick={() => this.setState({ editing: false })}
                                >
                                  Cancel
                                </Button>
                              </ButtonGroup>
                            </div>
                          )}
                        </div>
                      ) : (
                        <Box display="flex" alignItems="flex-start" >
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: "100%"}}>
                              <img
                                src={CardImg}
                                alt='card'
                                style={{maxHeight: '50px', maxWidth: '50px', width: '100%', height: '100%'}}
                              />
                              <Box
                                padding='4'
                                maxWidth='500px'
                              >
                                <Text
                                  variant='headingLg'
                                  as='h2'
                                >
                                  {t("dash.balance")} ({t("dash.deposit")})
                                </Text >
                                <span >
                                  {balance} {" \u{20AC} "}
                                </span >
                              </Box >
                            </div >
                            
                            {user.is_manager && (
                              <Button
                                variant={'plain'}
                                onClick={() => this.setState({editing: true})}
                              >
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M16.2929 7.29289L16.7071 7.70711L16 8.41421L7.70711 16.7071L6.29289 18.1213L5.5 20.5L8.5 19.5L20.5 7.5L19.5 5.5L16.2929 7.29289Z'
                                    stroke='black'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg >
                              </Button >
                            )}
                          </div >
                          <Button
                            variant={'primary'}
                            size={'micro'}
                            disabled
                          >
                            Add deposit
                          </Button >
                        </Box >
                      )}
                      
                    </div>
                  </div>
                )}
                {!_.isEmpty(credits) && (
                  <div >
                    <Text variant='headingMd'>{"Credits"}</Text>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%', marginTop: 20
                    }}
                    >
                      {credits.map((credit, index) => (
                        <Box alignItems='space-between' key={credit.count + index}>
                          <Text variant='bodyMd'>{credit.name}</Text>
                          <Text color='subdued'>
                            {credit.count}
                            {credit.count > 1 ? " credits" : " credit"}
                          </Text>
                        </Box>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {IS_TAX_AGENT && (
              <div className={'block-white'} style={{marginTop: 20}}>
                {fetchingBonus ? (
                  <div>
                    <Spinner />
                  </div>
                ) : (
                  <div>
                    <Box padding="4" maxWidth="500px" marginTop='10'>
                      <Text>
                        Your partner level is{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {bonusProgram.level}
                        </span>
                      </Text>
                      <Text>
                        Give you{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {bonusProgram.discount}% discount
                        </span>{" "}
                        to all services
                      </Text>
                      <p>
                        You need{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {bonusProgram.need_client}
                        </span>{" "}
                        clients or referrals for achieve next level
                      </p>
                    </Box>
                  </div>
                )}
              </div>
            )}
           
            {!user.is_tax_agent &&
              !(user.is_manager && user.company.is_tax_agent) && (
                (
                  fetchingPaymentInfo ||
                  currentPlan?.subscriptions?.status === 'trial' ||
                  (currentPlan?.subscriptions && !currentPlan?.subscriptions_omp) ||
                  currentPlan?.subscriptions_sales_tax ||
                  currentPlan?.subscriptions_epr ||
                  currentPlan?.subscriptions_omp
                ) && (
                <div style={{marginTop: 20}}>
                <Card title={"Current subscription plan"} sectioned>
                  {fetchingPaymentInfo ? (
                    <Spinner />
                  ) : (
                    <React.Fragment>
                      {currentPlan?.subscriptions?.status === 'trial' && (
                        <Text>
                          {t("dash.overPlan")} (
                          <Link className='Polaris-Link' to='/subscription'>
                            {t("dash.selectPlan")}
                          </Link>
                          ){t("dash.dot")}
                          <br />
                          {t("dash.canCont")}
                          {t("dash.dot")}
                        </Text>
                      )}

                      {currentPlan &&
                        currentPlan.subscriptions && !currentPlan.subscriptions_omp &&
                        this.renderPaymentInfoBlock(
                          "vat",
                          currentPlan.subscriptions
                        )}

                      {currentPlan &&
                        currentPlan.subscriptions &&
                        currentPlan.subscriptions_sales_tax && <br />}
                      
                      {currentPlan &&
                        currentPlan.subscriptions_sales_tax && !currentPlan.subscriptions_omp &&
                        this.renderPaymentInfoBlock(
                          "sales tax",
                          currentPlan.subscriptions_sales_tax
                        )}
                      {currentPlan && currentPlan.subscriptions_epr && <br />}
                      {currentPlan &&
                        currentPlan.subscriptions_epr &&
                        this.renderEprPaymentInfo(
                          currentPlan.subscriptions_epr
                        )}
                      {currentPlan &&
                        currentPlan.subscriptions_omp &&
                        this.renderPaymentInfoBlock(
                          "omp",
                          currentPlan.subscriptions_omp
                        )}
                    </React.Fragment>
                  )}
                </Card>
                  </div>
                )
              )}
            {!user.is_tax_agent && <br />}
            <UserGuide />
            {!IS_TAX_AGENT && (
              <>
                {thresholdUS && this.renderThresholdUS()}
                {thresholdEU && this.renderThresholdEU()}
                {thresholdEU && <br />}
              </>
            )}

            {IS_TAX_AGENT && (
              <Card title={t("dash.title.payInfo")}>
                {fetchingPaymentInfo ? (
                  <div>
                    <Spinner />
                  </div>
                ) : (
                  this.renderTaxAgentClients()
                )}
              </Card>
            )}

            {user.is_omp && this.renderTransactionsByCountryOmp()}
          </div>
        </div>

        {this.renderSubscriptionDialog()}
        <br />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  userGuide: state.user.userGuide,
  declarations: state.declarations.declarations,
  fetchingDeclarations: state.declarations.fetching,
  declarationsSalesTax: state.declarations.declarationsSalesTax,
  fetchDeclarationsSalesTax: state.declarations.fetching,
  fetchingSalesTax: state.declarations.fetchingSalesTax,
  fetchingEPR: state.declarations.fetchingListEPR,
  declarationsEpr: state.declarations.declarationsEpr,
  fetchEprDeclarations: state.declarations.fetching,
  currentPlan: state.subscription.currentPlan,
  news: state.dashboard.news,
  fetchingNews: state.dashboard.fetchingNews,
  dueDates: state.dueDates.dueDates,
  fetchingDueDates: state.dueDates.fetching,
  revenue: state.dashboard.revenue,
  paymentInfo: state.profile.paymentInfo,
  fetchingPaymentInfo: state.profile.fetchingPaymentInfo,
  thresholdEU: state.thresholds.thresholdEU,
  fetchingThresholdEU: state.thresholds.fetchingThresholdEU,
  thresholdUS: state.thresholds.thresholdUS,
  fetchingThresholdUS: state.thresholds.fetchingThresholdEU,
  lastUploaded: state.dashboard.lastUploaded,
  defaultLanguage: state.user.defaultLanguage,
  deemedVat: state.dashboard.deemedVat,
  fetchingDeemedVat: state.dashboard.fetchingDeemedVat,
  transByCountry: state.dashboard.transByCountry,
  fetchingTransByCountry: state.dashboard.fetchingTransByCountry,
  bonusProgram: state.tax.bonusProgram,
  fetchingBonus: state.tax.fetchingBonus,
  balance: state.dashboard.balance,
  fetchingBalance: state.dashboard.fetchingBalance,
  editingBalance: state.dashboard.editingBalance,
  fetchingCredits: state.dashboard.fetchingCredits,
  credits: state.dashboard.credits,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchCompanyDefaultLanguage: () => dispatch(fetchCompanyDefaultLanguage()),
    fetchLastUploadedTansation: () => dispatch(fetchLastUploadedTansation()),
    fetchNews: (params) => dispatch(fetchNews(params)),
    fetchDueDates: (params) => dispatch(fetchDueDates(params)),
    fetchDeclarations: (params) => dispatch(fetchDeclarations(params)),
    fetchDeclarationsSalesTax: (params) => dispatch(fetchDeclarationsSalesTax(params)),
    fetchEprDeclarations: (params) => dispatch(fetchEprDeclarations(params)),
    fetchThresholdEU: (params) => dispatch(fetchThresholdEU(params)),
    fetchThresholdUS: (params) => dispatch(fetchThresholdUS(params)),
    fetchPaymentInfo: () => dispatch(fetchPaymentInfo()),
    fetchDeemedVat: () => dispatch(fetchDeemedVat()),
    fetchTransByCountryOmp: () => dispatch(fetchTransByCountryOmp()),
    fetchBonusProgram: () => dispatch(fetchBonusProgram()),
    fetchBalanceDashboard: () => dispatch(fetchBalanceDashboard()),
    editBalance: (params) => dispatch(editBalance(params)),
    fetchCredits: () => dispatch(fetchCredits()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard))
);
