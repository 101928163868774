import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import {
  Page,
  Card,
  Layout,
  Button,
  FormLayout,
  TextField,
  ButtonGroup,
  Checkbox,
  Select,
  Tabs,
  Text,
  DataTable,
  Box,
} from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import Modal from "components/Modal/Modal";
import GoBackPageNavigation from '../../components/GoBackPageNavigation/GoBackPageNavigation'
import callApi from '../../helpers/callApi'
import { fetchBanksList } from '../../redux/features/manager/managerSlice'
import { fetchCountries } from '../../redux/features/other/otherSlice'
import { checkPromoCode, orderPaymentAddons } from '../../redux/features/payment/paymentSlice'
import {
  fetchCalculateAddons,
  fetchCurrentPlan,
  fetchSubscriptionPlans
} from '../../redux/features/subscription/subscriptionSlice'
import StripePayment from "../StripePayment/StripePayment";
import { Link } from "react-router-dom";
import Datepicker from "components/Datepicker/Datepicker";
import { formatMonth } from "utils/dates";
import formatRow from "utils/formatRow";
import { getCurrencySymbol } from "utils/currency";
import { formatMoney } from "utils/numbers";
import PageHelmet from "components/PageHelmet";
import { createCustomDispatch } from "helpers/customDispatch";
import { addonsConfig } from "containers/Subscription/constants";

import addonMinusIMG from "img/addonMinus.svg";
import addonPlusIMG from "img/addonPlus.svg";
import { buildPeriods } from "utils/periods";
import { getYears } from "utils/years";

const BillingMethods = Object.freeze({
  monthly: "monthly",
  annually: "annually",
});

const years = getYears({
  yearsBefore: 1,
  yearsAfter: 4,
  showDefaultValue: false,
}).map((year) => year.value);

const getAddonsConfigAdapter = (selected) => {
  if (selected === 2) {
    // in addons config OMP index is 4
    return addonsConfig[4];
  } else if (selected === 3) {
    // in addons config EPR index is 2
    return addonsConfig[2];
  }

  return addonsConfig[selected];
};

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}


class Addons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.location?.state?.tabIndex ?? 0,
      chosenPlan: null,

      paymentShopify: this.props.user.payment_to_shopify,
      paymentShopifyDisabled: true,
      currency: "",
      currencyIcon: "",
      promo: null,
      promoError: null,

      features: {},
      addonsItems: {},

      billing_method: BillingMethods.annually,

      actionDialogs: {
        subscribe: { open: false },
        notification: { open: false },
      },
      
      sbsYearFrom: moment().format("YYYY"),
      sbsYearTill: moment().format("YYYY"),
      sbsMonthFrom: moment().format("M"),
      sbsMonthTill: moment().add( "month").format("M"),
      sbsDateFrom: moment().startOf('month').format(),
      sbsDateTill: moment().add(1, "month").startOf('month').format(),
      
      other: "",
      unit: "%",
      discountManager: 0,

      isCurrent: true,
      bank: "",
    };
  }
  
  handleSubmit = async (isShopifyPay = false, res) => {
    const { promoCode } = this.props;
    const { save } = this.state;
    
    const dataTeh = {
      ...res,
      save,
      promo_code: promoCode,
      ...(isShopifyPay && { payments_type: "shopify" }),
    };
    
    this.setState({ calculate: dataTeh });
    
    try {
      const response = await callApi(
        "/payment/order_addons_button_redirect",
        "POST",
        dataTeh
      );
      
      if (response.ok) {
        const redirectUrl = await response.json();
        // console.log('Redirect URL:', redirectUrl);
        window.location.href = redirectUrl;
      } else {
        console.log('Error response:', response);
      }
    } catch (err) {
      console.log("Server error", err);
    }
  };

  componentDidMount() {
    this.props
      .fetchCurrentPlan()
      .then(() => {
        const { currentPlan } = this.props;
        const { selected } = this.state;
        const tabs = this.getAllTabs();

        const chosenPlan = this.getChosenPlan(tabs, selected);

        this.setState({
          chosenPlan,
          currency: currentPlan.currency_code,
          currencyIcon: getCurrencySymbol(currentPlan.currency_code),
        });
      })
      .catch(_.noop);

    this.props.fetchSubscriptionPlans().then(() => {
      this.setState({
        statesList:
          this.props.subscriptionPlans?.[0].tariff_plan_sales_tax?.[0]?.select_countries,
      });
    });

    this.props.fetchBanksList();
    this.props.fetchCountries();
  }

  getOptionsCountries = (selectCountries = []) => {
    return [
      {
        key: "none",
        label: "",
        value: "",
      },
      ...selectCountries.map((country) => ({
        key: country.code,
        label: country.name,
        value: country.code,
      })),
    ];
  };

  getOptionsAdditionalTypes = (addons, addonType) => {
    const { additional_type = [] } =
      addons.find(({ type }) => type === addonType) || {};

    return [
      {
        key: "none",
        label: "",
        value: "",
      },
      ...additional_type.map((type) => ({
        key: type.id,
        label: type.name,
        value: type.id.toString(),
      })),
    ];
  };

  getChosenPlan = (tabs, tabIndex) => {
    const { currentPlan } = this.props;
    const currentTabKey = tabs[tabIndex].key;
    return {
      tariff: currentPlan[currentTabKey].tariff_plan,
    };
  };

  getAllTabs = () => {
    const { currentPlan } = this.props;
    const hasOMP = Boolean(currentPlan?.subscriptions_omp)
    return [
      {
        id: "vat",
        content: "Vat Addons",
        accessibilityLabel: "Vat Addons",
        panelID: "vat-tab",
        condition: Boolean(currentPlan?.subscriptions) && !hasOMP,
        key: "subscriptions",
      },
      {
        id: "sales",
        content: "Sales Tax Addons",
        accessibilityLabel: "Sales Tax Addons",
        panelID: "sales-tab",
        condition: Boolean(currentPlan?.subscriptions_sales_tax),
        key: "subscriptions_sales_tax",
      },
      {
        id: "omp",
        content: "OMP Addons",
        accessibilityLabel: "OMP Addons",
        panelID: "omp",
        condition: Boolean(currentPlan?.subscriptions_omp),
        key: "subscriptions_omp",
      },
      {
        id: "epr",
        content: "EPR Addons",
        accessibilityLabel: "EPR Addons",
        panelID: "epr",
        condition: Boolean(currentPlan?.subscriptions_epr),
        key: "subscriptions_epr",
      },
    ];
  };

  getShownTabs = () => {
    return this.getAllTabs().filter((tab) => tab.condition)
  };

  getAddonItems = (
    addonValue,
    addonName,
    addonInState,
    withCountries,
    withAdditionalType = false
  ) => {
    const {
      chosenPlan,
      sbsDateFrom,
      sbsDateTill,
      sbsYearFrom,
      features,
      selected,
      addonsItems,
    } = this.state;
    const { t } = this.props;

    const addonItems = [];
    const datepickerProps = {
      showMonthYearPicker: true,
      showFullMonthYearPicker: true,
    };

    const onDateChange = (date, index) => {
      this.setState((prevState) => ({
        addonsItems: {
          ...prevState.addonsItems,
          [addonInState]: prevState.addonsItems[addonInState].map(
            (addon, i) => {
              if (index === i) {
                return { ...addon, ...date };
              }
              return addon;
            }
          ),
        },
      }));
    };
    
    const onFieldChange = (field, data, index) => {
      this.setState((prevState) => {
        const updatedAddonsItems = { ...prevState.addonsItems };
        if (addonInState === "dac7Report") {
          updatedAddonsItems[addonInState] = updatedAddonsItems[addonInState].map(
            (addon, i) => {
              const idAddon = chosenPlan?.tariff?.addons.find((a) => a.type === addon.type)
              if (index === i && addon.type === "dac7") {
                const newCount = field === "count" ? data : addon.count;
                return { ...addon, [field]: newCount, id: idAddon.id };
              }
              return addon;
            }
          );
        } else {
          updatedAddonsItems[addonInState] = updatedAddonsItems[addonInState].map(
            (addon, i) => {
              if (index === i) {
                return { ...addon, [field]: data, id: addon.id };
              }
              return addon;
            }
          );
        }
        
        return {
          addonsItems: updatedAddonsItems,
        };
      });
    };

    for (let index = 0; index < features[addonValue]; index++) {
      const thisAddon = addonsItems[addonInState][index];

      addonItems.push(
        <FormLayout.Group key={`${addonValue}${index}`} condensed>
          <TextField
            label={t("subscription.addonName")}
            disabled
            value={addonName}
          />
          {selected === 3 && addonName !== 'DAC7 report' && (
            <Select
              id='year'
              name='year'
              label={`Year:`}
              value={thisAddon.year ?? sbsYearFrom}
              options={years.map((year) => ({
                key: year,
                label: year,
                value: year.toString(),
              }))}
              onChange={(value) => onFieldChange("year", value, index)}
            />
          )}
          {
            selected !== 3 && addonName === "DAC7 report" && (
              <div style={{ display: "flex", alignItems: "flex-end", gap: "1px" }}>
                <button
                  onClick={() => {
                    onFieldChange("count", Math.max((addonsItems.dac7Report[0]?.count || 0) - 1, 0), 0, )
                  }}
                  style={{
                    width: "30px",
                    height: "35px",
                    textAlign: "center",
                    cursor: "pointer",
                    background: 'transparent',
                    border: '1px solid rgba(201, 204, 207, 1)',
                    borderRadius: '4px'
                  }}
                >
                  -
                </button>
                <TextField
                  label="Count"
                  value={(addonsItems.dac7Report[0]?.count || 0).toString()}
                  onChange={(value) =>
                    onFieldChange(
                      "count",
                      Math.max(parseInt(value, 10) || 0, 0),
                      0
                    )
                  }
                />
                <button
                  onClick={() =>
                    onFieldChange("count", (addonsItems.dac7Report[0]?.count || 0) + 1, 0)
                  }
                  style={{
                    width: "30px",
                    height: "35px",
                    textAlign: "center",
                    cursor: "pointer",
                    background: 'transparent',
                    border: '1px solid rgba(201, 204, 207, 1)',
                    borderRadius: '4px'
                  }}
                >
                  +
                </button>
              </div>
            )
          }
          
          
          {selected !== 3 && addonName !== 'DAC7 report' && (
            <Datepicker
              label={t("subscription.from")}
              datepickerProps={datepickerProps}
              allowRange={false}
              value={thisAddon.dateFrom ?? sbsDateFrom}
              customFormatDate={(date) => formatMonth(date)}
              onChange={(date) => {
                const year = moment(date).year();
                const month = moment(date).month() + 1;
                const dateObj = {
                  yearFrom: year,
                  monthFrom: month,
                  dateFrom: date,
                };
                onDateChange(dateObj, index);
              }}
            />
          )}
          
          {selected !== 3 && addonName !== 'DAC7 report'&& (
            
            <Datepicker
              label={t("subscription.till")}
              datepickerProps={datepickerProps}
              value={thisAddon.dateTill ?? sbsDateTill}
              allowRange={false}
              customFormatDate={(date) => formatMonth(date)}
              onChange={(date) => {
                const selectedYear = moment(date).year();
                const selectedMonth = moment(date).month() + 1; // Приводим к диапазону 1-12
                
                const fromDate = thisAddon.dateFrom ?? sbsDateFrom;
                const fromYear = moment(fromDate).year();
                const fromMonth = moment(fromDate).month() + 1;
                
                let adjustedYear = selectedYear;
                let adjustedMonth = selectedMonth;
                
                if (selectedYear === fromYear && selectedMonth === fromMonth) {
                  // Если месяц и год одинаковые - остаются такими же
                  adjustedMonth = fromMonth;
                  adjustedYear = fromYear;
                } else if (selectedYear === fromYear && selectedMonth === fromMonth + 1) {
                  // Если выбран следующий месяц - оставляем его без изменений
                  adjustedMonth = selectedMonth;
                } else if (selectedMonth < fromMonth && selectedYear === fromYear) {
                  // Если выбран месяц раньше, чем в "from", то не даем его выбрать
                  return;
                }
                
                const dateObj = {
                  yearTill: adjustedYear,
                  monthTill: adjustedMonth,
                  dateTill: moment(date).set("month", adjustedMonth - 1).format(),
                };
                
                // console.log("Передача данных:", dateObj);
                
                onDateChange(dateObj, index);
              }}
              minDateOverride={(() => {
                const fromDate = thisAddon.dateFrom ?? sbsDateFrom;
                if (!fromDate) return undefined;
                return new Date(fromDate);
              })()}
            />
            
            
            // <Datepicker
            //   label={t("subscription.till")}
            //   datepickerProps={datepickerProps}
            //   value={thisAddon.dateTill ?? sbsDateTill}
            //   allowRange={false}
            //   customFormatDate={(date) => formatMonth(date)}
            //   onChange={(date) => {
            //     const year = moment(date).year();
            //     const month = moment(date).month();
            //     const dateObj = {
            //       yearTill: year,
            //       monthTill: month,
            //       dateTill: date,
            //     };
            //     onDateChange(dateObj, index);
            //   }}
            //   minDateOverride={(() => {
            //     const fromDate = thisAddon.dateFrom ?? sbsDateFrom;
            //     if (!fromDate) return undefined;
            //     const fromDateObj = new Date(fromDate);
            //     fromDateObj.setMonth(fromDateObj.getMonth());
            //     return fromDateObj;
            //   })()}
            // />
          )}
          
          {withAdditionalType && (
            <Select
              error={!thisAddon.additional_type && t("subscription.required")}
              id={"id" + index}
              key={"additional-type_" + index}
              name={"name" + index}
              label={`Type`}
              options={this.getOptionsAdditionalTypes(
                chosenPlan?.tariff.addons,
                addonValue
              )}
              onChange={(value) => {
                onFieldChange("additional_type", value, index);
              }}
              value={thisAddon.additional_type}
            />
          )}

          {withCountries && (
            <Select
              error={!thisAddon.country && t("subscription.required")}
              id={"id" + index}
              key={"country_" + index}
              name={"name" + index}
              label={`${
                selected === 1 ? "State" : t("subscription.country")
              } (${index + 1})`}
              options={this.getOptionsCountries(
                chosenPlan &&
                  chosenPlan.tariff.name === "PAN-EU" &&
                  addonInState !== "additionalCountries"
                  ? this.props.countries.map((item, ind) => ({
                      key: ind,
                      ...item,
                    }))
                  : chosenPlan.tariff.select_countries.map((item, ind) => ({
                      key: ind,
                      ...item,
                    }))
              )}
              onChange={(value) => {
                onFieldChange("country", value, index);
              }}
              value={thisAddon.country}
            />
          )}

          {!withCountries && !withAdditionalType && <br />}
        </FormLayout.Group>
      );
    }

    return addonItems;
  };

  getAddonStructure = (id, addonItem) => {
    const { sbsYearFrom, sbsMonthFrom, sbsYearTill, sbsMonthTill, selected } =
      this.state;
    const addonStructure = {
      id,
      country: addonItem.country,
      type: addonItem.type,
    };

    if (selected !== 3) {
      addonStructure.year_from = addonItem.yearFrom
        ? +addonItem.yearFrom
        : +sbsYearFrom;
      addonStructure.month_from = addonItem.monthFrom
        ? +addonItem.monthFrom
        : +sbsMonthFrom;
      addonStructure.year_till = addonItem.yearTill
        ? +addonItem.yearTill
        : +sbsYearTill;
      addonStructure.month_till = addonItem.monthTill
        ? +addonItem.monthTill
        : +sbsMonthTill;
    }

    if (selected === 3) {
      addonStructure.year = addonItem.year ? +addonItem.year : +sbsYearFrom;
      addonStructure.additional_type_id = +addonItem.additional_type;
    }

    return addonStructure;
  };

  getPickerLang = () => {
    const { t } = this.props;

    return {
      months: [
        t("addons.janSh"),
        t("addons.febSh"),
        t("addons.marSh"),
        t("addons.aprSh"),
        t("addons.maySh"),
        t("addons.juneSh"),
        t("addons.julySh"),
        t("addons.augSh"),
        t("addons.septSh"),
        t("addons.octSh"),
        t("addons.novSh"),
        t("addons.decSh"),
      ],
    };
  };

  payTheAddons = (promoCode) => {
    const { other, bank, unit, discountManager, isCurrent, calculate } =
      this.state;
    const { user } = this.props;
    
    // const { year_from, year_till, month_from, month_till } = addon;
    //
    // // Если годы одинаковые
    // if (year_from === year_till) {
    //   const monthsDifference = month_till - month_from;
    //
    //   // Если разница месяцев равна нулю, возвращаем 1 месяц
    //   return monthsDifference > 0 ? monthsDifference : 1;
    // }
    //
    // // Если годы разные, то считаем количество месяцев для каждого года
    // const monthsInFromYear = 12 - month_from + 1; // Количество месяцев в году от month_from до декабря
    // const monthsInTillYear = month_till; // Количество месяцев в году от января до month_till
    //
    // // Суммируем месяцы с обоих годов
    // return monthsInFromYear + monthsInTillYear;

    const data = {
      ...calculate,
      promo_code: promoCode,
      to_current: isCurrent,
      // month:
    };

    if (user.is_manager) {
      data.other = other;
      data.bank = +bank;
      data.unit = unit;
      data.discount = +discountManager;
    }

    this.props
      .orderPaymentAddons({ data })
      .then((resp) => {
        this.setState({
          other: "",
          bank: "",
          promoCode: "",
          orderingStatus: resp,
          unit: "%",
          discountManager: 0,
        });
        this.handleActionDialogsClose("subscribe");
        this.handleActionDialogsOpen("notification");
      })
      .catch((resp) => {
        this.setState({
          orderingError: resp,
        });
      });
  };
  
  doPrepareDataForCalculate = () => {
    const { selected, addonsItems } = this.state;
    const { tariff } = this.state.chosenPlan;
    const subs_types = ["vat_subs", "sales_tax_subs", "omp_subs", "epr_subs"];
    const type = subs_types[selected];
    
    const additional = Object.keys(addonsItems)
      .map((addonInState) => {
        return addonsItems[addonInState].map((addon) => {
          if (addon.type === "dac7") {
            return { type: addon.type, amount: addon.count, id: addon.id };
          } else {
            const { id } = tariff.addons.find(
              (addonInList) => addonInList.type === addon.type
            );
            return this.getAddonStructure(id, addon);
          }
        });
      })
      .flat();
    
    const data = {
      additional,
      type,
    };

    this.props
      .fetchCalculateAddons({ data })
      .then((resp) => {
        this.setState({ calculate: resp });
        this.handleActionDialogsOpen("subscribe");
      })
      .catch((resp) => {
        this.setState({ calculateError: resp });
      });
  };


  checkPromo = (event) => {
    event.preventDefault();
    const { promoCode, calculate } = this.state;
    const data = {
      promo_code: promoCode,
      subscription: {
        additional: calculate.additional.map((item) => ({
          id: item.id,
          month_from: item.month_from,
          month_till: item.month_till,
          year_from: item.year_from,
          year_till: item.year_till,
        })),
      },
    };

    if (promoCode) {
      this.props
        .checkPromoCode(data)
        .then((resp) => {
          if (resp !== null) {
            this.setState({ promo: resp });
          } else {
            this.setState({
              promo: resp,
              promoError: true,
            });
          }
        })
        .catch((resp) => {
          this.setState({ checkingPromoError: resp });
        });
    }
  };

  styles = {
    tableRow: {
      padding: "12px 16px",
    },
    tableRowColumn: {
      width: "14%",
      verticalAlign: "middle",
      fontSize: "14px",
      height: "25px",
      textAlign: "right",
      padding: "10px 16px",
      borderBottom: "1px solid #DFE3E8",
    },
    tableRowColumnLeft: {
      width: "30%",
      padding: "5px 16px",
      verticalAlign: "middle",
      fontSize: "14px",
      textAlign: "left",
      whiteSpace: "normal",
      borderBottom: "1px solid #DFE3E8",
    },
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  doCloseSubscriptionDialog = () => {
    this.handleActionDialogsClose("subscribe");
    this.setState({
      promoError: false,
      bank: "",
      other: "",
      promoCode: "",
      unit: "%",
      discountManager: 0,
    });
  };

  renderSubscribeDialog() {
    const {
      calculate,
      actionDialogs,
      promo,
      currencyIcon,
      paymentShopify,
      paymentShopifyDisabled,
      bank,
      unit,
      discountManager,
      isCurrent,
    } = this.state;

    const { user, orderingAddons, t } = this.props;
    const banksList = [
      {
        key: "0",
        label: "",
        value: "",
      },
      ...this.props.banksList.map((bankItem) => ({
        key: bankItem.id,
        label: bankItem.bank_name,
        value: bankItem.id.toString(),
      })),
    ];

    let codeValue = null;
    let sum = 0;
    let discount = Math.round(discountManager);

    if (calculate && !user.is_manager) {
      sum = calculate.sum;
      if (promo) {
        sum = (calculate.sum / 100) * (100 - promo.discount);
        codeValue = promo.code;
      }
    }

    if (calculate && user.is_manager) {
      if (unit === "%") {
        sum =
          calculate.sum - Math.round((calculate.sum * discountManager) / 100);
        discount = Math.round((calculate.sum * discountManager) / 100);
      } else {
        sum = calculate.sum - discountManager;
      }
    }

    const monthsArr = buildPeriods({ months: true }).map(
      (month) => month.label
    );

    const onClose = () => this.doCloseSubscriptionDialog();

    const columns = [
      {
        property: "",
        header: {
          label: t("subscription.plan"),
        },
        cell: {
          formatters: [
            (_, { rowData: addon }) => {
              const addonName =
                addon.name[0].toUpperCase() + addon.name.slice(1);
              const addonCountry = addon.country ? addon.country.name : "";

              if (calculate.type === "epr_subs") {
                return `${addonName} ${addonCountry} ${addon.year}`;
              }
              if (addon.name === "DAC7 report") {
                return addon.name;
              }

              const addonFromDate = `${t("subscription.from")} ${
                monthsArr[addon.month_from]
              } ${addon.year_from}`;
              const addonTillDate = `${t("subscription.till")} ${
                monthsArr[addon.month_till]
              } ${addon.year_till}`;

              return `${addonName} ${addonCountry}  ${addonFromDate} ${addonTillDate}`;
            },
          ],
        },
      },
      {
        property: "sum_one_month",
        header: {
          label: t("subscription.price"),
        },
        cell: {
          formatters: [(value) => value + currencyIcon],
        },
      },
      {
        property: "month",
        header: {
          label: t("subscription.quantity"),
        },
        cell: {
          formatters: [ (_, { rowData: addon }) => {
            const { year_from, year_till, month_from, month_till } = addon;
            
            // Если годы одинаковые
            if (year_from === year_till) {
              const monthsDifference = month_till - month_from;
              
              // Если разница месяцев равна нулю, возвращаем 1 месяц
              return monthsDifference > 0 ? monthsDifference : 1;
            }
            
            // Если годы разные, то считаем количество месяцев для каждого года
            const monthsInFromYear = 12 - month_from + 1; // Количество месяцев в году от month_from до декабря
            const monthsInTillYear = month_till; // Количество месяцев в году от января до month_till
            
            // Суммируем месяцы с обоих годов
            return monthsInFromYear + monthsInTillYear;
          }],
        },
      },
      {
        property: "sum",
        header: {
          label: t("subscription.total"),
        },
        cell: {
          formatters: [(_, { rowData: addon }) => {
            console.log('add', addon)
          }],
        },
      },
    ];

    const rows = formatRow(calculate?.additional || [], columns);

    rows.push([
      t("subscription.promoDisc"),
      "",
      "",
      `${
        !user.is_manager
          ? codeValue
            ? `- ${codeValue ? Math.round((sum * promo.discount) / 100) : "0"}`
            : "0"
          : ""
      }
      ${user.is_manager ? `- ${discount}` : ""}
      ${currencyIcon}`,
    ]);

    return (
      actionDialogs.cellData && (
        <Modal
          title={t("subscription.subs")}
          description={"Check the info and select payment method"}
          visible={actionDialogs.subscribe.open}
          onClose={onClose}
          content={
            <>
              <br />
              <Card>
                <DataTable
                  columnContentTypes={["text", "text", "text", "numeric"]}
                  headings={columns.map(({ header }) => (
                    <Text fontWeight='semibold'>{header.label}</Text>
                  ))}
                  rows={rows}
                  hideScrollIndicator
                  showTotalsInFooter
                  totals={[
                    t("subscription.subTotal"),
                    "",
                    "",
                    Math.round(sum) + currencyIcon,
                  ]}
                  totalsName={{
                    singular: t("subscription.subTotal"),
                    plural: t("subscription.subTotal"),
                  }}
                />
              </Card>
              <br />
              <form autoComplete='off' onSubmit={this.checkPromo}>
                <FormLayout>
                  <Checkbox
                    label={"Add to current subscription"}
                    checked={isCurrent}
                    onChange={() =>
                      this.setState((prevState) => ({
                        isCurrent: !prevState.isCurrent,
                      }))
                    }
                  />
                  <FormLayout.Group>
                    {user.is_manager ? (
                      <Select
                        label={"Banks"}
                        options={banksList}
                        onChange={(value) => this.setState({ bank: value })}
                        value={bank}
                        error={!bank}
                      />
                    ) : null}
                    {user.is_manager ? (
                      <TextField
                        type='number'
                        error={
                          unit === "%"
                            ? discountManager > 100
                            : discountManager > calculate.sum
                        }
                        name={"disc"}
                        label={"Discount"}
                        value={discountManager.toString()}
                        onChange={(value) =>
                          this.setState({ discountManager: value })
                        }
                        connectedRight={
                          <Select
                            options={[
                              {
                                key: "%",
                                label: "%",
                                value: "%",
                              },
                              {
                                key: "sum",
                                label: "Sum",
                                value: "sum",
                              },
                            ]}
                            value={unit}
                            onChange={(value) => this.setState({ unit: value })}
                          />
                        }
                      />
                    ) : (
                      <TextField
                        name={"promoCode"}
                        label={t("addons.enterPromo")}
                        value={this.state.promoCode}
                        onChange={(promoCode) => this.setState({ promoCode })}
                        connectedRight={
                          <Button variant={'primary'} size={'micro'} submit>
                            {t("addons.apply")}
                          </Button>
                        }
                        helpText={
                          (promo && (
                            <Text variant="bodyMd" color="success">
                              {t("addons.promoApplied")}
                            </Text>
                          )) ||
                          (this.state.promoError && !promo && (
                            <Text variant="bodyMd" color="critical">
                              {t("addons.promoInvalid")}
                            </Text>
                          )) ||
                          ""
                        }
                        required
                      />
                    )}
                    <br />
                  </FormLayout.Group>

                  {user.is_manager && bank && (
                    <div style={{ maxWidth: "450px" }}>
                      <Text variant="bodyMd" color="critical">
                        {
                          _.find(this.props.banksList, ["id", +bank])
                            .description
                        }
                      </Text>
                    </div>
                  )}
                  {user.is_manager && (
                    <TextField
                      name={"other"}
                      label={"Comment"}
                      value={this.state.other}
                      onChange={(other) => this.setState({ other })}
                    />
                  )}
                </FormLayout>
              </form>
            </>
          }
          footer={
              <div  style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{width: 200}}><Button onClick={onClose}>{t("taxSettings.cancel")}</Button></div>
              <StripePayment
                data={{ ...this.state.calculate, to_current: isCurrent }}
                service={"addons"}
                onConfirm={this.handleSubmit}
                promoCode={this.state.promoCode}
                renderCustom={(onConfirm) => {
                  return (
                    <ButtonGroup>
                      {!paymentShopify && (
                        <Button
                          loading={orderingAddons}
                          variant={user.is_manager? 'primary' : ''}
                          disabled={user.is_manager && !bank}
                          onClick={() => this.payTheAddons(codeValue)}
                        >
                          {t("subscription.orderInv")}
                        </Button>
                      )}
                      
                      {(this.state.calculate?.shopify_pay && !this.state.calculate?.card_pay) &&(
                        <div style={{ margin: "0 10px" }}>
                      <Button
                        variant="primary"
                        onClick={() => {
                          this.handleSubmit(true, this.state.calculate)
                        }}
                      >
                        Buy now with Shopify Pay
                      </Button>
                    </div>
                      )}
                      {this.state.calculate?.card_pay && (
                        <Button variant={'primary'} onClick={onConfirm}>
                          Buy now with credit card
                        </Button>
                      )}
                    </ButtonGroup>
                  );
                }}
              />
              </div>
          }
        />
      )
    );
  }

  renderNotificationDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("addons.payment")}
        visible={actionDialogs.notification.open}
        description={
          <div style={{ maxWidth: "450px" }}>
            {t("subscription.thanksForReq")}
          </div>
        }
        onClose={() => {
          this.handleActionDialogsClose("notification");
        }}
      />
    );
  }
  
  renderFeatureCounter(feature, addonInState) {
    const { features } = this.state;
    
    const handleFeatureCounterClick = (value) => {
      this.setState((prevState) => {
        const prevStateFeature = prevState.features?.[feature] ?? 0;
        const prevStateAddonInState = prevState.addonsItems?.[addonInState] ?? [];
        if (feature === "dac7" && prevStateFeature >= 1 && value > 0) {
          return null;
        }
        
        const minValue = Math.max(prevStateFeature + value, 0);
        
        const newStateAddon = Boolean(minValue)
          ? [...prevStateAddonInState, { type: feature }]
          : prevStateAddonInState.slice(0, -1);
        
        return {
          features: {
            ...prevState.features,
            [feature]: minValue,
          },
          addonsItems: {
            ...prevState.addonsItems,
            [addonInState]: newStateAddon,
          },
        };
      });
    };
    
    return (
      <div className='featureCounter'>
      <Button
        variant={'plain'}
        icon={<img src={addonMinusIMG} alt='remove addon' />}
        onClick={() => handleFeatureCounterClick(-1)}
      />
        
        {features[feature] ?? 0}
        
        <Button
          variant={'plain'}
          icon={<img src={addonPlusIMG} alt='add addon' />}
          onClick={() => handleFeatureCounterClick(1)}
        />
    </div>
    );
  }
  
  
  renderCellWithFeatureCounter = (
    addon,
    addonName,
    addonValue,
    addonInState
  ) => {
    const { currencyIcon, billing_method } = this.state;
    const { t } = this.props;

    if (
      addon.name === addonName &&
      billing_method === BillingMethods.annually
    ) {
      return (
        <div>
          {!addon.included && (
            <div className='featureCounterContainer'>
              {(addon.price_year && `${addon.price_year} ${currencyIcon}`) || (
                <span>&mdash;</span>
              )}
              {addon.price_year
                ? this.renderFeatureCounter(addonValue, addonInState)
                : null}
            </div>
          )}

          {addon.included && <span>{t("subscription.included")}</span>}
        </div>
      );
    }
    
    if (addon.name === addonName && billing_method === BillingMethods.monthly) {
      return (
        <div>
          {!addon.included && (
            <div className='featureCounterContainer'>
              {(addon.price_month &&
                `${addon.price_month} ${currencyIcon}`) || <span>&mdash;</span>}
              {addon.price_month
                ? this.renderFeatureCounter(addonValue, addonInState)
                : null}
            </div>
          )}

          {addon.included && <span>{t("subscription.included")}</span>}
        </div>
      );
    }
  };

  renderFeatures() {
    const { selected, chosenPlan, features, additionalCountries } = this.state;
    const { t } = this.props;
    const countriesNumber =
      chosenPlan && chosenPlan.tariff.countries_to_select
        ? +chosenPlan.tariff.countries_to_select +
          features.each_additional_country
        : 0;

    const addons = getAddonsConfigAdapter(selected);

    const optionsCountries = [
      {
        key: "null",
        label: "",
        value: "",
      },
    ];

    if (chosenPlan && chosenPlan.tariff.select_countries) {
      /*countryItems = */ chosenPlan.tariff.select_countries.map((country) => {
        optionsCountries.push({
          key: country.code,
          label: country.name,
          value: country.code,
        });
      });
    }

    const additionalCountriesItems = [];

    for (let index = 0; index < countriesNumber; index++) {
      additionalCountriesItems.push(
        <Select
          id={"id" + index}
          key={"country_" + index}
          name={"name" + index}
          label={`${t("addons.country")} (${index + 1})`}
          options={optionsCountries}
          value={additionalCountries[index] || ""}
          onChange={(value) => {
            // eslint-disable-line
            additionalCountries[index] = value;
            this.setState({ additionalCountries });
          }}
        />
      );
    }

    const additionalCountriesItemsForEU = [];

    if (chosenPlan && chosenPlan.tariff.included_countries) {
      chosenPlan.tariff.included_countries.forEach((country, index) => {
        additionalCountriesItemsForEU.push(
          <TextField
            label={`${t("addons.country")} (${index + 1})`}
            key={country.code}
            disabled
            value={country.name}
          />
        );
      });
    }

    const renderRow = (name, addonName, addonValue, addonInState) => (
      <tr style={this.styles.tableRow}>
        <td style={this.styles.tableRowColumnLeft}>{t(name)}</td>
        <td style={this.styles.tableRowColumn}>
          {chosenPlan.tariff.addons.map((addon, ind) => (
            <div key={`${ind}${addon.id}`}>
              {this.renderCellWithFeatureCounter(
                addon,
                addonName,
                addonValue,
                addonInState
              )}
            </div>
          ))}
        </td>
      </tr>
    );

    const renderEmptyRow = (text) => (
      <tr style={this.styles.tableRow}>
        <td style={this.styles.tableRowColumnLeft}>{text}</td>
        <td style={this.styles.tableRowColumn}>
          <Link className='Polaris-Link' to='/subscription'>
            select plan
          </Link>
        </td>
      </tr>
    );

    return (
      <table style={{ width: "100%", borderSpacing: "0px" }}>
        <tbody>
          {selected === 0 && this.props.currentPlan.subscriptions && (
            <React.Fragment>
              {addons.map((row, index) =>
                <React.Fragment key={row.translationKey || index}>
                {renderRow(
                  row.translationKey,
                  row.addonName,
                  row.addonValue,
                  row.addonInState
                )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {selected === 0 &&
            !this.props.currentPlan.subscriptions &&
            renderEmptyRow("To purchase addons, you need a VAT subscription")}

          {selected === 1 && this.props.currentPlan.subscriptions_sales_tax && (
            <React.Fragment>
              {addons.map((row, index) =>
                <React.Fragment key={row.translationKey || index}>
                {renderRow(
                  row.translationKey,
                  row.addonName,
                  row.addonValue,
                  row.addonInState
                )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {selected === 1 &&
            !this.props.currentPlan.subscriptions_sales_tax &&
            renderEmptyRow(
              "To purchase addons, you need a Sales tax subscription"
            )}

          {selected === 2 && this.props.currentPlan.subscriptions_omp && (
            <React.Fragment>
              {addons.map((row, index) =>
                <React.Fragment key={row.translationKey || index}>
                {renderRow(
                  row.translationKey,
                  row.addonName,
                  row.addonValue,
                  row.addonInState
                )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {selected === 2 &&
            !this.props.currentPlan.subscriptions_omp &&
            renderEmptyRow("To purchase addons, you need an OMP subscription")}

          {selected === 3 && this.props.currentPlan.subscriptions_epr && (
            <React.Fragment>
              {addons.map((row, index) =>
                <React.Fragment key={row.translationKey || index}>
                {renderRow(
                  row.translationKey,
                  row.addonName,
                  row.addonValue,
                  row.addonInState
                )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {selected === 3 &&
            !this.props.currentPlan.subscriptions_epr &&
            renderEmptyRow("To purchase addons, you need an EPR subscription")}
        </tbody>
      </table>
    );
  }

  renderAddonsSection = () => {
    const { selected } = this.state;
    const { t } = this.props;
    const addons = getAddonsConfigAdapter(selected);

    const addonItems = addons.map((config) =>
      this.getAddonItems(
        config.addonValue,
        t(config.translationKey),
        config.addonInState,
        config.withCountries,
        config.withAdditionalType
      )
    );

    return <FormLayout>{addonItems}</FormLayout>;
  };
  
  // getIsPaymentSectionButtonDisabled = () => {
  //   const { addonsItems, selected } = this.state;
  //   console.log('addonsItems', addonsItems)
  //   console.log('selected', selected)
  //   const {
  //     additionalCountries = [],
  //     additionalTaxRep = [],
  //     additionalIntrastat = [],
  //     additionalListOfSales = [],
  //     additionalAutoSub = [],
  //     dac7Report = [],
  //     additionalVatCountry = [],
  //     additionalCountryPackaging = [],
  //     authorizedRepresentative = [],
  //     additionalReport = [],
  //     additionalType = [],
  //     additionalPstReturn = [],
  //   } = addonsItems;
  //
  //   if (selected === 2 && (!dac7Report || dac7Report?.[0]?.count === undefined || dac7Report?.[0]?.count === 0)) {
  //     return true;
  //   }
  //
  //   if (addonsItems){return true}
  //
  //   const requiredAutoSub = additionalAutoSub.find(
  //     (item) => item && !item.country
  //   );
  //
  //   const requiredDac7Countries = dac7Report.find(
  //     (item) => item?.type === "dac7" && item?.count === 0
  //   );
  //
  //   const requiredVatCountries = additionalVatCountry.find(
  //     (item) => item && !item.country
  //   );
  //
  //   return [
  //     additionalCountries.find((item) => item && !item.country),
  //     additionalListOfSales.find((item) => item && !item.country),
  //     additionalIntrastat.find((item) => item && !item.country),
  //     additionalTaxRep.find((item) => item && !item.country),
  //     additionalPstReturn.find((item) => item && !item.country),
  //     [
  //       ...additionalType,
  //       ...authorizedRepresentative,
  //       ...additionalCountryPackaging,
  //       ...additionalReport,
  //     ].find((item) => item && !item.additional_type),
  //     selected === 1 && requiredAutoSub,
  //     selected === 2 && !!requiredDac7Countries,
  //     selected === 2 && requiredVatCountries,
  //   ].some(Boolean);
  // };
  
  getIsPaymentSectionButtonDisabled = () => {
    const { addonsItems, selected } = this.state;
    
    if (!addonsItems) {
      return true;
    }
    
    const {
      additionalCountries = [],
      additionalTaxRep = [],
      additionalIntrastat = [],
      additionalListOfSales = [],
      additionalAutoSub = [],
      dac7Report = [],
      additionalVatCountry = [],
      additionalCountryPackaging = [],
      authorizedRepresentative = [],
      additionalReport = [],
      additionalType = [],
      additionalPstReturn = [],
    } = addonsItems;
    
    
    const shouldCheckDateTill = [
      additionalCountries,
      additionalTaxRep,
      additionalIntrastat,
      additionalListOfSales,
      additionalAutoSub,
      dac7Report,
      additionalVatCountry,
      additionalCountryPackaging,
      authorizedRepresentative,
      additionalReport,
      additionalType,
      additionalPstReturn,
    ].every((array) => array.every((item) => item?.country || item?.additional_type || item?.count > 0));
    
    if (
      selected === 2 &&
      (!dac7Report || dac7Report?.[0]?.count === undefined || dac7Report?.[0]?.count === 0)
    ) {
      if (shouldCheckDateTill) {
        return false;
      } else {
        return true;
      }
    }
    
    const requiredAutoSub = additionalAutoSub.find((item) => item && !item.country);
    const requiredDac7Countries = dac7Report.find(
      (item) => item?.type === 'dac7' && item?.count === 0
    );
    const requiredVatCountries = additionalVatCountry.find(
      (item) => item && !item.country
    );
    
    const isDisabled = [
      additionalCountries.find((item) => item && !item.country),
      additionalListOfSales.find((item) => item && !item.country),
      additionalIntrastat.find((item) => item && !item.country),
      additionalTaxRep.find((item) => item && !item.country),
      additionalPstReturn.find((item) => item && !item.country),
      [
        ...additionalType,
        ...authorizedRepresentative,
        ...additionalCountryPackaging,
        ...additionalReport,
      ].find((item) => item && !item.additional_type),
      selected === 1 && requiredAutoSub,
      selected === 2 && !!requiredDac7Countries,
      selected === 2 && requiredVatCountries,
    ].some(Boolean);
    return isDisabled;
  };
  
  
  
  renderPaymentSection() {
    const { fetchingCalcAddons, t } = this.props;
    const { features } = this.state;

    const emptyAddons = !Object.values(features).some(Boolean);
  
    return (
      <Card sectioned>
        {emptyAddons ? (
          <Text color='subdued'>No addons are selected</Text>
        ) : (
          <>
            {this.renderAddonsSection()}
            <br />
            <div>
               <Button
                 variant={'primary'}
                 size={'micro'}
                 loading={fetchingCalcAddons}
                 disabled={this.getIsPaymentSectionButtonDisabled()}
                 onClick={() => this.doPrepareDataForCalculate()}
               >
                {t("addons.subs")}
              </Button>
            </div>
          </>
        )}
      </Card>
    );
  }

  // onChangeBillingMethod = (method) => {
  //   this.setState((prevState) => {
  //     console.log('prevState', prevState)
  //     return {
  //       billing_method: method,
  //       sbsYearFrom: moment().format("YYYY"),
  //       sbsMonthFrom: moment().format("M"),
  //       sbsDateFrom: moment().format(),
  //
  //       sbsYearTill:
  //         prevState.billing_method !== BillingMethods.annually
  //           ? moment().add(1, "year").format("YYYY")
  //           : moment().format("YYYY"),
  //       sbsMonthTill:
  //         prevState.billing_method !== BillingMethods.annually
  //           ? moment().add(1, "year").subtract(1, "month").format("M")
  //           : moment().format("M"),
  //       sbsDateTill:
  //         prevState.billing_method !== BillingMethods.annually
  //           ? moment().add(1, "year").format()
  //           : moment().format(),
  //     };
  //   });
  // };
  
  onChangeBillingMethod = (method) => {
    this.setState((prevState) => {
      const currentYear = moment().format("YYYY");
      const currentMonth = moment().format("M");
      const nextYear = moment().add(1, "year").format("YYYY");
      const nextMonth = moment().add(1, "month").format("M");

      let sbsYearTill;
      let sbsMonthTill;
      let sbsDateTill;

      if (method !== BillingMethods.annually) {
        sbsYearTill = currentYear;
        sbsMonthTill = currentMonth;
        sbsDateTill = moment().format();
      } else {
        if (nextMonth === currentMonth) {
          // Если месяц совпадает, считать как один месяц
          sbsYearTill = currentYear;
          sbsMonthTill = currentMonth;
          sbsDateTill = moment().endOf("month").format();
        } else {
          // Если месяц следующий, вести себя как раньше
          sbsYearTill = nextYear;
          sbsMonthTill = moment().add(1, "year").subtract(1, "month").format("M");
          sbsDateTill = moment().add(1, "year").format();
        }
      }
      return {
        billing_method: method,
        sbsYearFrom: currentYear,
        sbsMonthFrom: currentMonth,
        sbsDateFrom: moment().format(),
        sbsYearTill,
        sbsMonthTill,
        sbsDateTill,
      };
    });
  };

  getSubscriptionPrice = (type) => {
    const { currentPlan } = this.props;

    switch (type) {
      case "vat":
        return formatMoney(currentPlan.subscriptions?.sum);
      case "sales tax":
        return formatMoney(currentPlan.subscriptions_sales_tax?.sum);
      case "omp":
        return formatMoney(currentPlan.subscriptions_omp?.sum);
      default:
        return null;
    }
  };

  renderCurrentPlan = (type, subscription) => {
    const { currentPlan, t } = this.props;
    const { chosenPlan, billing_method, selected } = this.state;

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("addons.addons")}
          </Text>
        }
        description={
          <Text variant={'bodySm'}>
            {t("addons.selectAddons")}
          </Text>
        }
      >
        <br />
        <Card
          sectioned
          title={<Text variant='headingMd'>{`Current ${type} plan`}</Text>}
        >
          <Box padding="4" maxWidth="500px">
            <Text as="p" variant="bodyMd">
              <Text as="h6" variant="headingMd"  fontWeight="bold">
                {t("addons.tariffPlan")}: {subscription.tariff_plan.name}
              </Text>
            </Text>
            
            <div>
              {t("addons.yourMemb1")}
              {getCurrencySymbol(currentPlan.currency_code)}
              {this.getSubscriptionPrice(type)}
              {t("addons.yourMemb2")}
            </div>
          </Box>
        </Card>
        {chosenPlan && currentPlan && (
          <Card>
            <div style={{ padding: "1.2rem", paddingBottom: "0.5rem"}}>
                <Box display="flex" alignItems="space-between">
                  <div style={{marginBottom: 20}}>
                    <Text variant='headingMd'>{`Select new`}</Text>
                  </div>
                
                {selected !== 2 && selected !== 3 && (
                  <ButtonGroup segmented>
                    <Button
                      variant={billing_method === BillingMethods.monthly? 'primary': ''}
                      size={'micro'}
                      onClick={() =>
                        this.onChangeBillingMethod(BillingMethods.monthly)
                      }
                    >
                      {t("subscription.monthly")}
                    </Button>
                    <Button
                      size={'micro'}
                      variant={billing_method === BillingMethods.annually? 'primary': ''}
                      onClick={() =>
                        this.onChangeBillingMethod(BillingMethods.annually)
                      }
                    >
                      {t("subscription.annually")} (-15%)
                    </Button>
                  </ButtonGroup>
                )}
              </Box>
            </div>
            {this.renderFeatures()}
          </Card>
        )}
      </Layout.AnnotatedSection>
    );
  };

  renderAddonsPlanInfo() {
    const { currentPlan } = this.props;
    const { selected } = this.state;

    if (currentPlan) {
      switch (selected) {
        case 0: {
          return (
            currentPlan.subscriptions &&
            this.renderCurrentPlan("vat", currentPlan.subscriptions)
          );
        }
        case 1: {
          return (
            currentPlan.subscriptions_sales_tax &&
            this.renderCurrentPlan(
              "sales tax",
              currentPlan.subscriptions_sales_tax
            )
          );
        }
        case 2: {
          return (
            currentPlan.subscriptions_omp &&
            this.renderCurrentPlan("omp", currentPlan.subscriptions_omp)
          );
        }
        case 3: {
          return (
            currentPlan.subscriptions_epr &&
            this.renderCurrentPlan("epr", currentPlan.subscriptions_epr)
          );
        }
        default:
          return null;
      }
    }

    return null;
  }

  render() {
    const { t } = this.props;
    const { selected, chosenPlan } = this.state;
    const tabs = this.getAllTabs();
    const shownTabs = this.getShownTabs();

    return (
      <Page
        title={
          <GoBackPageNavigation content={
            <Text variant='heading3xl' as='span'>
            {t("addons.buyAddons")}
          </Text>
          } />
        }
        fullWidth
      >
        <PageHelmet title={"Addons"} />
        
        <Tabs
          tabs={shownTabs}
          selected={shownTabs.findIndex((tab) => tab.id === tabs[selected].id)}
          onSelect={(tabIndex) => {
            this.setState((prevState) => {
              const selected = tabs.findIndex(
                (tab) => tab.id === shownTabs[tabIndex].id
              );
              const defaultBillingMethod =
                selected === 2 || selected === 3
                  ? BillingMethods.annually
                  : prevState.billing_method;
              
              const chosenPlan = this.getChosenPlan(tabs, selected);

              return {
                selected,
                chosenPlan,
                billing_method: defaultBillingMethod,
                features: {},
                addonsItems: {},
              };
            });
          }}
        />
        <div className={'company-settings'}>
        <Layout>
          {this.renderAddonsPlanInfo()}
          {chosenPlan && (
            <Layout.AnnotatedSection
              title={
                <Text variant='headingLg' as='p'>
            {t("addons.detailsAddons")}
          </Text>
              }
              description={
                <Text variant={'bodySm'}>
            {t("addons.fillInDetails")}
          </Text>
              }
            >
              {this.renderPaymentSection()}
            </Layout.AnnotatedSection>
          )}
        </Layout>
       
        {this.renderSubscribeDialog()}
        {this.renderNotificationDialog()}
           </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  fetchingCalcAddons: state.subscription.fetchingCalcAddons,
  subscriptionPlans: state.subscription.subscriptionPlans,
  orderingAddons: state.payment.orderingAddons,
  currentPlan: state.subscription.currentPlan,
  defaultLanguage: state.user.defaultLanguage,
  banksList: state.manager.banksList,
  countries: state.other.countries,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    orderPaymentAddons: (data) => dispatch(orderPaymentAddons(data)),
    checkPromoCode: (data) => dispatch(checkPromoCode(data)),
    fetchCalculateAddons: (params) => dispatch(fetchCalculateAddons(params)),
    fetchCurrentPlan: () => dispatch(fetchCurrentPlan()),
    fetchBanksList: () => dispatch(fetchBanksList()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchSubscriptionPlans: () => dispatch(fetchSubscriptionPlans()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Addons))
);
