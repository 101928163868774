import { t } from 'i18next'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import withRouter from "helpers/withRouter";
import {
  Page,
  Card,
  Button,
  FormLayout,
  TextField,
  ButtonGroup,
  Checkbox,
  Select,
  Tabs,
  Spinner,
  Text,
  DataTable,
} from "@shopify/polaris";
import _, { isEmpty } from "lodash";
import Modal from "components/Modal";
import Datepicker from "components/Datepicker/Datepicker";
import GoBackPageNavigation from '../../components/GoBackPageNavigation/GoBackPageNavigation'
import callApi from '../../helpers/callApi'
import { fetchBanksList } from '../../redux/features/manager/managerSlice'
import { fetchCountries } from '../../redux/features/other/otherSlice'
import { checkPromoCode, orderPayment } from '../../redux/features/payment/paymentSlice'
import { fetchCalculateSubscription, fetchSubscriptionPlans } from '../../redux/features/subscription/subscriptionSlice'
import StripePayment from "../StripePayment/StripePayment";

import { formatMonth } from "utils/dates";
import formatRow from "utils/formatRow";
import { getCurrencySymbol } from "utils/currency";
import { formatMoney } from "utils/numbers";
import PageHelmet from "components/PageHelmet";
import classNames from "classnames";
import { buildPeriods } from "utils/periods";
import { createCustomDispatch } from "helpers/customDispatch";
import { BillingMethods, addonsConfig } from "./constants";
import Plan from "./components/Plan";

import addonMinusIMG from "img/addonMinus.svg";
import addonPlusIMG from "img/addonPlus.svg";

import "./index.scss";
import { getYears } from "utils/years";

const years = getYears({
  yearsBefore: 1,
  yearsAfter: 4,
  showDefaultValue: false,
}).map((year) => year.value);

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      selected: this.props.user.is_omp ? 4 : 0,
      chosenPlan: undefined,
      paymentShopify: this.props.user.payment_to_shopify,
      paymentShopifyDisabled: true,
      currency: "",
      currencyIcon: "",
      promo: null,
      promoError: null,
      
      features: {},
      addonsItems: {},
      
      billing_method: BillingMethods.annually,
      actionDialogs: {
        subscribe: { open: false },
        notification: { open: false },
        buyCredits: { open: false },
      },
      sbsYearFrom: moment().format("YYYY"),
      sbsYearTill: moment().format("YYYY"),
      sbsMonthFrom: moment().format("M"),
      sbsMonthTill: moment().add( "month").format("M"),
      sbsDateFrom: moment().startOf('month').format(),
      sbsDateTill: moment().add(1, "month").startOf('month').format(),
      
      sbsCountry: "",
      
      yearEPR: years[0],
      categoryEPR: "",
      
      other: "",
      unit: "%",
      discountManager: 0,
    };
  }
  
  
  handleSubmit = async (isShopifyPay = false, res) => {
    const { promoCode } = this.props;
    const { save } = this.state;
    
    const dataTeh = {
      ...res,
      save,
      promo_code: promoCode,
      ...(isShopifyPay && { payments_type: "shopify" }),
    };
    
    this.setState({ calculate: dataTeh });
    
    try {
      const response = await callApi(
        "/payment/order_subscription_button_redirect",
        "POST",
        dataTeh
      );
      
      if (response.ok) {
        const redirectUrl = await response.json();
        // console.log('Redirect URL:', redirectUrl);
        window.location.href = redirectUrl;
      } else {
        console.log('Error response:', response);
      }
    } catch (err) {
      console.log("Server error", err);
    }
  };

  
  componentDidMount() {
    this.props.fetchCountries();
    
    this.props
      .fetchSubscriptionPlans()
      .then(() => {
        this.setState({
          currency: this.props.subscriptionPlans[0].currency,
          currencyIcon: getCurrencySymbol(
            this.props.subscriptionPlans[0].currency
          ),
        });
      })
      .catch(_.noop);
    this.props.fetchBanksList();
  }
  
  getOptionsCountries = (selectCountries = []) => {
    return [
      {
        key: "none",
        label: "",
        value: "",
      },
      ...selectCountries.map((country) => ({
        key: country.code,
        label: country.name,
        value: country.code,
      })),
    ];
  };
  
  getOptionsAdditionalTypes = (addons, addonType) => {
    const { additional_type = [] } =
    addons.find(({ type }) => type === addonType) || {};
    
    return [
      {
        key: "none",
        label: "",
        value: "",
      },
      ...additional_type.map((type) => ({
        key: type.id,
        label: type.name,
        value: type.id.toString(),
      })),
    ];
  };
  
  getAddonItems = (
    addonValue,
    addonName,
    addonInState,
    withCountries,
    withAdditionalType = false
  ) => {
    const {
      chosenPlan,
      sbsDateFrom,
      sbsDateTill,
      features,
      selected,
      addonsItems,
    } = this.state;
    const { t } = this.props;
    
    const addonItems = [];
    
    const datepickerProps = {
      showMonthYearPicker: true,
      showFullMonthYearPicker: true,
      minDate: new Date(sbsDateFrom),
      maxDate: new Date(sbsDateTill),
    };
    
    const onDateChange = (date, index) => {
      this.setState((prevState) => ({
        addonsItems: {
          ...prevState.addonsItems,
          [addonInState]: prevState.addonsItems[addonInState].map(
            (addon, i) => {
              if (index === i) {
                return { ...addon, ...date };
              }
              return addon;
            }
          ),
        },
      }));
    };
    
    const onFieldChange = (field, data, index) => {
      this.setState((prevState) => ({
        addonsItems: {
          ...prevState.addonsItems,
          [addonInState]: prevState.addonsItems[addonInState].map(
            (addon, i) => {
              const idAddon = chosenPlan?.tariff?.addons.find((a) => a.type === addon.type)
              if (index === i) {
                return { ...addon, [field]: data, id: idAddon.id };
              }
              return addon;
            }
          ),
        },
      }));
    };
    
    for (let index = 0; index < features[addonValue]; index++) {
      const thisAddon = addonsItems[addonInState][index];
      addonItems.push(
        <FormLayout.Group key={`${addonValue}${index}`} condensed>
          <TextField
            label={t("subscription.addonName")}
            disabled
            value={addonName}
          />
          {
            selected !== 2 && addonName === "DAC7 report" && (
              <div style={{ display: "flex", alignItems: "flex-end", gap: "1px" }}>
                <button
                  onClick={() =>
                    onFieldChange("count", Math.max((addonsItems.dac7Report[0]?.count || 0) - 1, 0), 0)
                  }
                  style={{
                    width: "30px",
                    height: "35px",
                    textAlign: "center",
                    cursor: "pointer",
                    background: 'transparent',
                    border: '1px solid rgba(201, 204, 207, 1)',
                    borderRadius: '4px'
                  }}
                >
                  -
                </button>
                <TextField
                  label="Count"
                  value={(addonsItems.dac7Report[0]?.count || 0).toString()}
                  onChange={(value) =>
                    onFieldChange(
                      "count",
                      Math.max(parseInt(value, 10) || 0, 0),
                      0
                    )
                  }
                />
                <button
                  onClick={() =>
                    onFieldChange("count", (addonsItems.dac7Report[0]?.count || 0) + 1, 0)
                  }
                  style={{
                    width: "30px",
                    height: "35px",
                    textAlign: "center",
                    cursor: "pointer",
                    background: 'transparent',
                    border: '1px solid rgba(201, 204, 207, 1)',
                    borderRadius: '4px'
                  }}
                >
                  +
                </button>
              </div>
            )
          }
          {selected !== 2 && addonName !== 'DAC7 report' && (
            <Datepicker
              label={t("subscription.from")}
              datepickerProps={datepickerProps}
              allowRange={false}
              value={thisAddon.dateFrom ?? sbsDateFrom}
              customFormatDate={(date) => formatMonth(date)}
              onChange={(date) => {
                const year = moment(date).year();
                const month = moment(date).month() + 1;
                const dateObj = {
                  yearFrom: year,
                  monthFrom: month,
                  dateFrom: date,
                };
                onDateChange(dateObj, index);
              }}
            />
          )}
          
          {selected !== 2 && addonName !== 'DAC7 report' && (
            // <Datepicker
            //   label={t("subscription.till")}
            //   datepickerProps={datepickerProps}
            //   value={thisAddon.dateTill ?? sbsDateTill}
            //   allowRange={false}
            //   customFormatDate={(date) => formatMonth(date)}
            //   onChange={(date) => {
            //     const year = moment(date).year();
            //     const month = moment(date).month() + 1;
            //     const dateObj = {
            //       yearTill: year,
            //       monthTill: month,
            //       dateTill: date,
            //     };
            //     onDateChange(dateObj, index);
            //   }}
            // />
            <Datepicker
              label={t("subscription.till")}
              datepickerProps={datepickerProps}
              value={thisAddon.dateTill ?? sbsDateTill}
              allowRange={false}
              customFormatDate={(date) => formatMonth(date)}
              onChange={(date) => {
                const selectedYear = moment(date).year();
                const selectedMonth = moment(date).month() + 1; // Приводим к диапазону 1-12
                
                const fromDate = thisAddon.dateFrom ?? sbsDateFrom;
                const fromYear = moment(fromDate).year();
                const fromMonth = moment(fromDate).month() + 1;
                
                let adjustedYear = selectedYear;
                let adjustedMonth = selectedMonth;
                
                if (selectedYear === fromYear && selectedMonth === fromMonth) {
                  // Если месяц и год одинаковые - остаются такими же
                  adjustedMonth = fromMonth;
                  adjustedYear = fromYear;
                } else if (selectedYear === fromYear && selectedMonth === fromMonth + 1) {
                  // Если выбран следующий месяц - оставляем его без изменений
                  adjustedMonth = selectedMonth;
                } else if (selectedMonth < fromMonth && selectedYear === fromYear) {
                  // Если выбран месяц раньше, чем в "from", то не даем его выбрать
                  return;
                }
                
                const dateObj = {
                  yearTill: adjustedYear,
                  monthTill: adjustedMonth,
                  dateTill: moment(date).set("month", adjustedMonth - 1).format(),
                };
                onDateChange(dateObj, index);
              }}
              minDateOverride={(() => {
                const fromDate = thisAddon.dateFrom ?? sbsDateFrom;
                if (!fromDate) return undefined;
                return new Date(fromDate);
              })()}
            />
          )}
          
          {withAdditionalType && (
            <Select
              error={!thisAddon.additional_type && t("subscription.required")}
              id={"id" + index}
              key={"additional-type_" + index}
              name={"name" + index}
              label={`Type`}
              options={this.getOptionsAdditionalTypes(
                chosenPlan?.tariff.addons,
                addonValue
              )}
              onChange={(value) => {
                onFieldChange("additional_type", value, index);
              }}
              value={thisAddon.additional_type}
            />
          )}
          
          {
            withCountries &&
              <Select
                error={!thisAddon.country && t("subscription.required")}
                id={"id" + index}
                key={"country_" + index}
                name={"name" + index}
                label={`${
                  selected === 1 ? "State" : t("subscription.country")
                } (${index + 1})`}
                options={this.getOptionsCountries(
                  chosenPlan &&
                  chosenPlan.tariff.name === "PAN-EU" &&
                  addonInState !== "additionalCountries"
                    ? this.props.countries.map((item, ind) => ({
                      key: ind,
                      ...item,
                    }))
                    : chosenPlan.tariff.select_countries.map((item, ind) => ({
                      key: ind,
                      ...item,
                    }))
                )}
                onChange={(value) => {
                  onFieldChange("country", value, index);
                }}
                value={thisAddon.country}
              />
          }
          
          {!withCountries && !withAdditionalType && <br />}
        </FormLayout.Group >
      );
    }
    return addonItems;
  };
  
  getAddonStructure = (id,
    addonItem) => {
    const {sbsYearFrom, sbsMonthFrom, sbsYearTill, sbsMonthTill, selected} =
      this.state;
    
    const obj = {
      id,
      year_from: addonItem.yearFrom ? +addonItem.yearFrom : +sbsYearFrom,
      month_from: addonItem.monthFrom ? +addonItem.monthFrom : +sbsMonthFrom,
      year_till: addonItem.yearTill ? +addonItem.yearTill : +sbsYearTill,
      month_till: addonItem.monthTill ? +addonItem.monthTill : +sbsMonthTill,
      country: addonItem.country,
      type: addonItem.type,
    };
    
    if (selected === 2) {
      obj.additional_type_id = +addonItem.additional_type;
    }
    
    return obj;
  };
  
  getPickerLang = () => {
    const { t } = this.props;
    
    return {
      months: [
        t("subscription.janSh"),
        t("subscription.febSh"),
        t("subscription.marSh"),
        t("subscription.aprSh"),
        t("subscription.maySh"),
        t("subscription.juneSh"),
        t("subscription.julySh"),
        t("subscription.augSh"),
        t("subscription.septSh"),
        t("subscription.octSh"),
        t("subscription.novSh"),
        t("subscription.decSh"),
      ],
    };
  };
  
  payTheSubscription = (promoCode) => {
    const { other, bank, unit, discountManager, calculate } = this.state;
    const { user } = this.props;
    const data = {
      ..._.cloneDeep(calculate),
      promo_code: promoCode,
    };
    
    if (user.is_manager) {
      data.other = other;
      data.bank = +bank;
      data.unit = unit;
      data.discount = +discountManager;
    }
    
    this.props
      .orderPayment(data)
      .then((resp) => {
        this.setState({
          other: "",
          bank: "",
          promoCode: "",
          orderingStatus: resp,
          unit: "%",
          discountManager: 0,
        });
        this.handleActionDialogsClose("subscribe");
        this.handleActionDialogsOpen("notification");
      })
      .catch((resp) => {
        this.setState({
          orderingError: resp,
        });
      });
  };
  
  payTheCredits = (promoCode) => {
    const { other, bank, unit, discountManager, actionDialogs } = this.state;
    const data = { ...actionDialogs.cellData };
    const { user } = this.props;
    
    data.promo_code = promoCode;
    data.type = "credits";
    
    if (user.is_manager) {
      data.other = other;
      data.bank = +bank;
      data.unit = unit;
      data.discount = +discountManager;
    }
    
    this.props
      .orderPayment(data)
      .then((resp) => {
        this.setState({
          other: "",
          bank: "",
          promoCode: "",
          orderingStatus: resp,
          unit: "%",
          discountManager: 0,
        });
        this.handleActionDialogsClose("subscribe");
        this.handleActionDialogsOpen("notification");
      })
      .catch((resp) => {
        this.setState({
          orderingError: resp,
        });
      });
  };
  
  resetSelectedPlan = () => {
    this.setState({
      sbsCountry: "",
      features: {},
      addonsItems: {},
      chosenPlan: null,
    });
  };
  
  handleSelectPlan = (tariff) => {
    this.setState({ chosenPlan: { tariff } });
  };
  
  doPrepareDataForCalculate = () => {
    const {
      sbsYearFrom,
      sbsMonthFrom,
      sbsYearTill,
      sbsMonthTill,
      sbsCountry,
      selected,
      yearEPR,
      billing_method,
      textComment,
      features,
      addonsItems,
      chosenPlan,
    } = this.state;
    const { tariff } = chosenPlan;
    const { additionalCountries = [] } = addonsItems;
    
    const additional = Object.keys(addonsItems)
      .map((addonInState) => {
        return addonsItems[addonInState].map((addon) => {
          if (addon.type === "dac7") {
            
            return { type: addon.type, amount: addon.count, id: addon.id };
          }
          const { id } = tariff.addons.find(
            (addonInList) => addonInList.type === addon.type
          );
          const addonStructure = this.getAddonStructure(id, addon);
          
          if (addonStructure.country === undefined) {
            delete addonStructure.country;
          }
          
          return addonStructure;
        });
      })
      .flat();
    
    const types = ["vat_subs", "sales_tax_subs", "epr_subs", "credits", "omp"];
    const type = types[selected];
    const data = {
      type,
      id_sbs: tariff.id,
      year_from_sbs: selected === 2 ? +yearEPR : +sbsYearFrom,
      month_from_sbs:
        selected === 2 ? +moment().startOf("year").format("MM") : +sbsMonthFrom,
      year_till_sbs: selected === 2 ? +yearEPR : +sbsYearTill,
      month_till_sbs:
        selected === 2 ? +moment().endOf("year").format("MM") : +sbsMonthTill,
      additional,
    };
    
    if (selected !== 1) {
      if(!tariff.epr_plan_subscription ) {
        data.country = sbsCountry;
      }
    }
    
    this.props
      .fetchCalculateSubscription({ data })
      .then((resp) => {
        this.setState({ calculate: resp });
        
        const countriesListString =
          additionalCountries.length > 0 &&
          additionalCountries.reduce((previousValue, currentItem) => {
            return previousValue + currentItem.name + " ";
          }, "");
        
        this.handleActionDialogsOpen("subscribe", {
          method: billing_method,
          features:
            "additional countries: " +
            features.each_additional_country +
            ", additional number of transactions: " +
            features.additional_number_of_transactions +
            ", additional value: " +
            features.additional_value_for_each_100000,
          additionalCountries: countriesListString,
          comment: textComment,
        });
      })
      .catch((resp) => {
        this.setState({ calculateError: resp });
      });
  };
  
  checkPromo = (event, d) => {
    event.preventDefault();
    const { promoCode, calculate } = this.state;
    const data = d || {
      promo_code: promoCode,
      subscription: {
        additional: calculate.additional.map((item) => ({
          id: item.id,
          month_from: item.month_from,
          month_till: item.month_till,
          year_from: item.year_from,
          year_till: item.year_till,
        })),
        id_sbs: calculate.id_sbs,
        month_from_sbs: calculate.month_from_sbs,
        month_till_sbs: calculate.month_till_sbs,
        year_from_sbs: calculate.year_from_sbs,
        year_till_sbs: calculate.year_till_sbs,
      },
    };
    
    if (promoCode) {
      this.props
        .checkPromoCode(data)
        .then((resp) => {
          if (resp !== null) {
            this.setState({ promo: resp });
          } else {
            this.setState({
              promo: resp,
              promoError: true,
            });
          }
        })
        .catch((resp) => {
          this.setState({ checkingPromoError: resp });
        });
    }
  };
  
  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };
  
  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };
  
  doCloseSubscriptionDialog = () => {
    this.handleActionDialogsClose("subscribe");
    this.setState({
      promoError: false,
      bank: "",
      other: "",
      promoCode: "",
      unit: "%",
      discountManager: 0,
    });
  };
  
  
  renderSubscribeDialog() {
    const {
      calculate,
      actionDialogs,
      promo,
      chosenPlan,
      currencyIcon,
      paymentShopify,
      paymentShopifyDisabled,
      bank,
      unit,
      discountManager,
      selected,
      yearEPR,
    } = this.state;
    
    const { user, ordering, t } = this.props;
    const banksList = [
      {
        key: "0",
        label: "",
        value: "",
      },
      ...this.props.banksList.map((bankItem) => ({
        key: bankItem.id,
        label: bankItem.bank_name,
        value: bankItem.id.toString(),
      })),
    ];
    
    let codeValue = null;
    let sum = 0;
    let discount = Math.round(discountManager);
    
    if (calculate && !user.is_manager) {
      sum = calculate.sum;
      if (promo) {
        sum = (calculate.sum / 100) * (100 - promo.discount);
        codeValue = promo.code;
      }
    }
    
    if (calculate && user.is_manager) {
      if (unit === "%") {
        sum =
          calculate.sum - Math.round((calculate.sum * discountManager) / 100);
        discount = Math.round((calculate.sum * discountManager) / 100);
      } else {
        sum = calculate.sum - discountManager;
      }
    }
    
    const onClose = () => this.doCloseSubscriptionDialog();
    
    const monthsArr = buildPeriods({ months: true }).map(
      (month) => month.label
    );
    
    const columns = [
      {
        property: "",
        header: {
          label: t("subscription.plan"),
        },
        cell: {
          formatters: [
            (value, { rowData: addon }) => {
              if (addon.name === "DAC7 report") {
                return addon.name;
              }
              return addon.name ?
                `${addon.name?.[0].toUpperCase() + addon?.name.slice(1)} ${
                addon?.country ? addon?.country.name : ""
              } ${
                addon.annually
                  ? `(annual) ${addon.year_from} - ${addon.additional_type.name}`
                  : `${t("subscription.from")} ${monthsArr[addon.month_from]} ${
                    addon.year_from
                  } ${t("subscription.till")} ${
                    monthsArr[addon.month_till]
                  } ${addon.year_till}`
              }`
                :
                `${addon.type?.[0].toUpperCase() + addon?.type.slice(1)} ${
                  addon?.country ? addon?.country.name : ""
                } ${
                  addon.annually
                    ? `(annual) ${addon.year_from} - ${addon.additional_type.name}`
                    : `${t("subscription.from")} ${monthsArr[addon.month_from]} ${
                      addon.year_from
                    } ${t("subscription.till")} ${
                      monthsArr[addon.month_till]
                    } ${addon.year_till}`
                }`
            },
          ],
        },
      },
      {
        property: "sum_one_month",
        header: {
          label: t("subscription.price"),
        },
        cell: {
          formatters: [(value) => formatMoney(value) + currencyIcon],
        },
      },
      {
        property: "month",
        header: {
          label: t("subscription.quantity"),
        },
      },
      {
        property: "sum",
        header: {
          label: t("subscription.total"),
        },
        cell: {
          formatters: [(value) => formatMoney(value) + currencyIcon],
        },
      },
    ];
    
    const rows = formatRow(calculate?.additional || [], columns);
    
    if (calculate && !isEmpty(calculate.additional))
      rows.unshift([
        <Text fontWeight='semibold'>{t("subscription.addons")}:</Text>,
        "",
        "",
        "",
      ]);
    
    if (calculate) {
      const renderBillingMethod =
        selected === 4 ? "" : `(${actionDialogs.cellData.method})`;
      
      const firstEl =
        selected === 2
          ? `Epr reporting subscription (annual) ${yearEPR} ${
            calculate.country ? calculate.country.name : ""
          }`
          : `${chosenPlan?.tariff.name || ""} ${renderBillingMethod} ${
            calculate.country ? calculate.country.name : ""
          } ${t("subscription.from")} ${
            monthsArr[calculate.month_from_sbs]
          } ${calculate.year_from_sbs} ${t("subscription.till")} ${
            monthsArr[calculate.month_till_sbs]
          } ${calculate.year_till_sbs}`;
      
      rows.unshift([
        firstEl,
        calculate.sum_sbs_one_month + currencyIcon,
        calculate.month_sbs,
        calculate.sum_sbs + currencyIcon,
      ]);
    }
    
    if (user.is_tax_agent) {
      rows.push([
        `Tax agent discount (${calculate?.tax_agent_percent}%)`,
        "",
        "",
        ` - ${calculate?.tax_agent_discount} ${currencyIcon}`,
      ]);
    }
    
    rows.push([
      user.is_manager ? "Discount" : t("subscription.promoDisc"),
      "",
      "",
      `${
        !user.is_manager && codeValue
          ? `- ${Math.round((calculate.sum * promo.discount) / 100)}`
          : ""
      }
        ${!user.is_manager && !codeValue ? "0" : ""}
        ${user.is_manager ? `- ${discount}` : ""}
        ${currencyIcon}`,
    ]);
    
    return (
      !isEmpty(actionDialogs.cellData) && (
        <Modal
          title={t("subscription.subs")}
          description={"Check the info and select payment method"}
          visible={actionDialogs.subscribe.open}
          onClose={onClose}
          content={
            <>
              <br />
              <Card>
                <DataTable
                  columnContentTypes={["text", "text", "text", "numeric"]}
                  headings={columns.map(({ header }) => (
                    <Text fontWeight='semibold'>{header.label}</Text>
                  ))}
                  rows={rows}
                  hideScrollIndicator
                  showTotalsInFooter
                  totals={[
                    t("subscription.subTotal"),
                    "",
                    "",
                    Math.round(sum) + currencyIcon,
                  ]}
                  totalsName={{
                    singular: t("subscription.subTotal"),
                    plural: t("subscription.subTotal"),
                  }}
                />
              </Card>
              <br />
              <form autoComplete='off' onSubmit={this.checkPromo} style={{width: '100%'}}>
                <FormLayout>
                  <FormLayout.Group>
                    {user.is_manager && (
                      <Select
                        label={"Banks"}
                        options={banksList}
                        onChange={(value) => this.setState({ bank: value })}
                        value={bank}
                        error={!bank}
                      />
                    )}
                    {user.is_manager ? (
                      <TextField
                        type='number'
                        error={
                          unit === "%"
                            ? discountManager > 100
                            : discountManager > calculate?.sum
                        }
                        name={"disc"}
                        label={"Discount"}
                        value={discountManager.toString()}
                        onChange={(value) =>
                          this.setState({ discountManager: value })
                        }
                        connectedRight={
                          <Select
                            options={[
                              {
                                key: "%",
                                label: "%",
                                value: "%",
                              },
                              {
                                key: "sum",
                                label: "Sum",
                                value: "sum",
                              },
                            ]}
                            value={unit}
                            onChange={(value) => this.setState({ unit: value })}
                          />
                        }
                      />
                    ) : (
                      <TextField
                        name={"promoCode"}
                        label={t("subscription.enterPromo")}
                        value={this.state.promoCode}
                        onChange={(promoCode) => this.setState({ promoCode })}
                        connectedRight={
                          <Button variant={'primary'} submit size={'micro'}>
                            {t("subscription.apply")}
                          </Button>
                        }
                        helpText={
                          (promo && (
                            <Text variant="bodyMd" color="success">
                              {t("subscription.promoApplied")}
                            </Text>
                          )) ||
                          (this.state.promoError && !promo && (
                            <Text variant="bodyMd" color="critical">
                              {t("subscription.promoInvalid")}
                            </Text>
                          )) ||
                          ""
                        }
                        required
                      />
                    )}
                    <br />
                  </FormLayout.Group>
                  
                  {user.is_manager && bank && (
                    <Text variant="bodyMd" color="critical">
                      {_.find(this.props.banksList, ["id", +bank]).description}
                    </Text>
                  )}
                  {user.is_manager && (
                    <TextField
                      name={"other"}
                      label={"Comment"}
                      value={this.state.other}
                      onChange={(other) => this.setState({ other })}
                    />
                  )}
                </FormLayout>
              </form>
            </>
          }
          footer={
          <div
            style={{
              display: "flex",
              alignItems: 'center',
              justifyContent: "space-between",
              width: "100%",
            }}
              >
            <div style={{width: '200px'}}>
              <Button onClick={onClose}>{t("taxSettings.cancel")}</Button>
            </div>
            
          <StripePayment
            data={this.state.calculate}
            promoCode={this.state.promoCode}
            onConfirm={this.handleSubmit}
            renderCustom={(onConfirm) => (
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                {!paymentShopify && (
                  <Button
                    loading={ordering}
                    variant={user.is_manager ? "primary" : ""}
                    disabled={user.is_manager && !bank}
                    onClick={(e) => {
                      e.preventDefault();
                      this.payTheSubscription(codeValue);
                    }}
                  >
                    {t("subscription.orderInv")}
                  </Button>
                )}
                  
                  {this.state.calculate?.shopify_pay && (
                    <div style={{ margin: "0 10px" }}>
                      <Button
                        variant="primary"
                        onClick={() => {
                          this.handleSubmit(true, this.state.calculate)
                        }}
                      >
                        Buy now with Shopify Pay
                      </Button>
                    </div>
                  )}
                  {this.state.calculate?.card_pay && (
                    <Button
                      variant="primary"
                      onClick={onConfirm}
                    >
                     Buy now with credit card
                    </Button>
                  )}
              </div>
            )}
          />
            </div>
          }
        />
      )
    );
  }
  
  renderNotificationDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;
    
    return (
      <Modal
        title={t("subscription.payment")}
        visible={actionDialogs.notification.open}
        onClose={() => {
          this.handleActionDialogsClose("notification");
        }}
        description={
          <div style={{ maxWidth: "450px" }}>
            {t("subscription.thanksForReq")}
          </div>
        }
      />
    );
  }
  
  renderBuyCredits() {
    const {
      actionDialogs,
      promo,
      bank,
      unit,
      discountManager,
      currencyIcon,
      promoCode,
      paymentShopify,
      paymentShopifyDisabled,
    } = this.state;
    
    const { user, t, ordering } = this.props;
    const banksList = [
      {
        key: "0",
        label: "",
        value: "",
      },
      ...this.props.banksList.map((bankItem) => ({
        key: bankItem.id,
        label: bankItem.bank_name,
        value: bankItem.id.toString(),
      })),
    ];
    
    const chosenCreditBundle = actionDialogs.cellData
      ? [actionDialogs.cellData]
      : [];
    
    const columns = [
      {
        property: "name",
        header: {
          label: "Credits",
        },
      },
      {
        property: "price_per_one",
        header: {
          label: "Price per one return",
        },
        cell: {
          formatters: [(value) => formatMoney(value) + currencyIcon],
        },
      },
      {
        property: "quantity",
        header: {
          label: t("subscription.quantity"),
        },
      },
      {
        property: "price",
        header: {
          label: t("subscription.total"),
        },
        cell: {
          formatters: [(value) => formatMoney(value) + currencyIcon],
        },
      },
    ];
    
    let codeValue = null;
    let sum = 0;
    
    if (actionDialogs.cellData && !user.is_manager) {
      sum = actionDialogs.cellData.price;
      if (promo) {
        sum = (actionDialogs.cellData.price / 100) * (100 - promo.discount);
        codeValue = promo.code;
      }
    }
    
    const rows = formatRow(chosenCreditBundle, columns);
    
    const onClose = () => {
      this.handleActionDialogsClose("buyCredits");
    };
    
    return (
      !isEmpty(actionDialogs.cellData) && (
        <Modal
          title={"Purchase credits"}
          description={"Check the info and select payment method"}
          visible={actionDialogs.buyCredits.open}
          onClose={onClose}
          content={
            <>
              <br />
              <Card>
                <DataTable
                  columnContentTypes={["text", "text", "text", "numeric"]}
                  headings={columns.map(({ header }) => (
                    <Text fontWeight='semibold'>{header.label}</Text>
                  ))}
                  rows={rows}
                  hideScrollIndicator
                  showTotalsInFooter
                  totals={[
                    t("subscription.subTotal"),
                    "",
                    "",
                    formatMoney(sum) + currencyIcon,
                  ]}
                  totalsName={{
                    singular: t("subscription.subTotal"),
                    plural: t("subscription.subTotal"),
                  }}
                />
              </Card>
              <br />
              <form
                autoComplete='off'
                onSubmit={(e) =>
                  this.checkPromo(e, {
                    type: "credits",
                    id: actionDialogs.cellData.id,
                    promo_code: promoCode,
                  })
                }
              >
                <FormLayout>
                  <FormLayout.Group>
                    {user.is_manager && (
                      <Select
                        label={"Banks"}
                        options={banksList}
                        onChange={(value) => this.setState({ bank: value })}
                        value={bank}
                        error={!bank}
                      />
                    )}
                    {user.is_manager ? (
                      <TextField
                        type='number'
                        name={"disc"}
                        label={"Discount"}
                        value={discountManager.toString()}
                        onChange={(value) =>
                          this.setState({ discountManager: value })
                        }
                        connectedRight={
                          <Select
                            options={[
                              {
                                key: "%",
                                label: "%",
                                value: "%",
                              },
                              {
                                key: "sum",
                                label: "Sum",
                                value: "sum",
                              },
                            ]}
                            value={unit}
                            onChange={(value) => this.setState({ unit: value })}
                          />
                        }
                      />
                    ) : (
                      <TextField
                        name={"promoCode"}
                        label={t("subscription.enterPromo")}
                        value={this.state.promoCode}
                        onChange={(promoCode) => this.setState({ promoCode })}
                        connectedRight={
                          <Button variant={'primary'} submit size={'micro'}>
                            {t("subscription.apply")}
                          </Button>
                        }
                        helpText={
                          (promo && (
                            <Text variant="bodyMd" color="success">
                              {t("subscription.promoApplied")}
                            </Text>
                          )) ||
                          (this.state.promoError && !promo && (
                            <Text variant="bodyMd" color="critical">
                              {t("subscription.promoInvalid")}
                            </Text>
                          )) ||
                          ""
                        }
                        required
                      />
                    )}
                    <br />
                  </FormLayout.Group>
                  
                  {user.is_manager && bank && (
                    <Text variant="bodyMd" color="critical">
                      {_.find(this.props.banksList, ["id", +bank]).description}
                    </Text>
                  )}
                  {user.is_manager && (
                    <TextField
                      name={"other"}
                      label={"Comment"}
                      value={this.state.other}
                      onChange={(other) => this.setState({ other })}
                    />
                  )}
                </FormLayout>
              </form>
            </>
          }
          footer={
            <div  style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{width: 200}}>
              <Button onClick={onClose}>
                {t("taxSettings.cancel")}
              </Button>
              </div>
              <StripePayment
                data={{ ...actionDialogs.cellData, type: "credits" }}
                promoCode={this.state.promoCode}
                onConfirm={this.handleSubmit}
                renderCustom={(onConfirm) => {
                  return (
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                      {!paymentShopify && (
                        <Button
                          loading={ordering}
                          variant={user.is_manager? 'primary': ''}
                          disabled={user.is_manager && !bank}
                          onClick={() => {
                            this.payTheCredits(codeValue);
                          }}
                        >
                          {t("subscription.orderInv")}
                        </Button>
                      )}
                      {this.state.calculate?.shopify_pay && (
                        <div style={{ margin: "0 10px" }}>
                          <Button
                            variant="primary"
                            onClick={() => {
                              this.handleSubmit(true, this.state.calculate)
                            }}
                          >
                            Buy now with Shopify Pay
                          </Button>
                        </div>
                      )}
                      {this.state.calculate?.card_pay && (
                        <Button variant={'primary'} onClick={onConfirm}>
                          Buy now with credit card
                        </Button>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          }
        />
      )
    );
  }
  
  renderFeatureCounter(selected, feature, addonInState) {
    const { features } = this.state;
    
    const handleFeatureCounterClick = (value) => {
      this.setState((prevState) => {
        const prevStateFeature = prevState.features?.[feature];
        const prevStateAddonInState =
          prevState.addonsItems?.[addonInState] ?? [];
        const minValue = Math.max(value, 0);
        if (feature === "dac7" && prevStateFeature >= 1 && value > 0) {
          return null;
        }
        
        const newStateAddon = Boolean(minValue)
          ? [...(prevStateAddonInState ?? []), { type: feature }]
          : prevStateAddonInState.slice(0, -1);
        
        return {
          features: {
            ...prevState.features,
            [feature]: prevStateFeature ? prevStateFeature + value : minValue,
          },
          addonsItems: {
            ...prevState.addonsItems,
            [addonInState]: newStateAddon,
          },
        };
      });
    };
    
    return selected ? (
      <div className='featureCounter'>
        <Button
          variant={'plain'}
          icon={<img src={addonMinusIMG} alt='remove addon' />}
          onClick={() => handleFeatureCounterClick(-1)}
        />
        
        {features[feature] ?? 0}
        
        <Button
          plain
          icon={<img src={addonPlusIMG} alt='add addon' />}
          onClick={() => handleFeatureCounterClick(1)}
        />
      </div>
    ) : undefined;
  }
  
  renderCellWithFeatureCounter = (
    addon,
    addonName,
    addonValue,
    selected,
    addonInState
  ) => {
    const { currencyIcon, sbsCountry, billing_method } = this.state;
    const { t } = this.props;
    
    const annualyPrice =
      sbsCountry === "SAU" && addon.name === "tax rep"
        ? addon.price_year_saudi
        : addon.price_year;
    const monthlyPrice =
      sbsCountry === "SAU" && addon.name === "tax rep"
        ? addon.price_month_saudi
        : addon.price_month;
    
    if (
      addon.name === addonName &&
      billing_method === BillingMethods.annually
    ) {
      return (
        <div>
          {!addon.included && (
            <div className='featureCounterContainer'>
              {(annualyPrice && `${annualyPrice} ${currencyIcon}`) || (
                <span>&mdash;</span>
              )}
              {annualyPrice
                ? this.renderFeatureCounter(selected, addonValue, addonInState)
                : null}
            </div>
          )}
          
          {addon.included && <span>{t("subscription.included")}</span>}
        </div>
      );
    }
    if (addon.name === addonName && billing_method === BillingMethods.monthly) {
      return (
        <div>
          {!addon.included && (
            <div className='featureCounterContainer'>
              {(monthlyPrice && `${monthlyPrice} ${currencyIcon}`) || (
                <span>&mdash;</span>
              )}
              {monthlyPrice
                ? this.renderFeatureCounter(selected, addonValue, addonInState)
                : null}
            </div>
          )}
          
          {addon.included && <span>{t("subscription.included")}</span>}
        </div>
      );
    }
  };
  
  renderFeatures(plans) {
    const { chosenPlan, selected } = this.state;
    const { t } = this.props;
    const selectedId = chosenPlan ? chosenPlan.tariff.id : -1;
    
    const renderRow = (
      name,
      addonName,
      addonValue,
      addonInState,
      isLastRow
    ) => (
      <tr className='tableRow'>
        <td className='tableRowColumnLeft'>{t(name)}</td>
        
        {plans.map((tariff, index) => (
          <td
            key={`${index}${name}`}
            className={classNames("tableRowColumn", {
              tableRowColumnSelected: tariff.id === selectedId,
              tableRowColumnSelectedLast: tariff.id === selectedId && isLastRow,
            })}
          >
            {tariff.addons.map((addon, ind) => (
              <div key={`${ind}${addon.id}`}>
                {this.renderCellWithFeatureCounter(
                  addon,
                  addonName,
                  addonValue,
                  tariff.id === selectedId,
                  addonInState
                )}
              </div>
            ))}
          </td>
        ))}
      </tr>
    );
    
    const addons = addonsConfig[selected] || [];
    
    return (
      <div>
        <table style={{ width: "100%", borderSpacing: "0px" }}>
          {addons.map((row, index) =>
            renderRow(
              row.translationKey,
              row.addonName,
              row.addonValue,
              row.addonInState,
              index === addons.length - 1
            )
          )}
        </table>
      </div>
    );
  }
  
  renderAdditionalCountries() {
    const { t } = this.props;
    const { chosenPlan } = this.state;
    const additionalCountriesItemsForEU = [];
    
    if (chosenPlan && chosenPlan.tariff.included_countries) {
      chosenPlan.tariff.included_countries.forEach((country, index) => {
        additionalCountriesItemsForEU.push(
          <TextField
            label={`${t("subscription.country")} (${index + 1})`}
            key={country.code}
            disabled
            value={country.name}
          />
        );
      });
    }
    
    return (
      <>
        {chosenPlan &&
          chosenPlan.tariff.name === "PAN-EU" &&
          chosenPlan.tariff.included_countries && (
            <Card sectioned>
              <>
                <Text variant='bodyMd' fontWeight='medium'>
                  {t("subscription.add.countries")}
                </Text>
                <br />
                <FormLayout>
                  <FormLayout.Group>
                    {additionalCountriesItemsForEU}
                    <br />
                    <br />
                    <br />
                    <br />
                  </FormLayout.Group>
                </FormLayout>
              </>
            </Card>
          )}
      </>
    );
  }
  
  renderAddonsSection = () => {
    const { selected } = this.state;
    const { t } = this.props;
    const addonItems = addonsConfig[selected].map((config) =>
      this.getAddonItems(
        config.addonValue,
        t(config.translationKey),
        config.addonInState,
        config.withCountries,
        config.withAdditionalType
      )
    );
    
    return <FormLayout>{addonItems}</FormLayout>;
  };
  
  getIsPaymentSectionButtonDisabled = () => {
    const { addonsItems, chosenPlan, sbsCountry, selected } = this.state;
    
    if (chosenPlan && selected === 2) {
      return false;
    }
    const {
      additionalCountries = [],
      additionalTaxRep = [],
      additionalIntrastat = [],
      additionalType = [],
      additionalListOfSales = [],
      additionalCountryPackaging = [],
      authorizedRepresentative = [],
      additionalReport = [],
      additionalAutoSub = [],
      dac7Report = [],
      additionalVatCountry = [],
      additionalPstReturn = [],
    } = addonsItems;

    if (dac7Report.length > 0 &&( dac7Report?.[0]?.count === undefined || dac7Report?.[0]?.count === 0)) {
      return true;
    }
 
    const requiredAutoSub = additionalAutoSub.find(
      (item) => item && !item.country
    );
    
    const requiredDac7Countries = dac7Report.find(
      (item) => item?.type === "dac7" && item?.count === 0
    );
    
    const requiredVatCountries = additionalVatCountry.find(
      (item) => item && !item.country
    );

    return [
      !chosenPlan,
      !sbsCountry && chosenPlan?.tariff?.name !== "PAN-EU" && selected !== 1,
      additionalCountries.find((item) => item && !item.country),
      additionalListOfSales.find((item) => item && !item.country),
      additionalIntrastat.find((item) => item && !item.country),
      additionalTaxRep.find((item) => item && !item.country),
      additionalPstReturn.find((item) => item && !item.country),
      [
        ...additionalType,
        ...authorizedRepresentative,
        ...additionalCountryPackaging,
        ...additionalReport,
      ].find((item) => item && (!item.additional_type)),
      selected === 1 && requiredAutoSub,
      selected === 4 && !!requiredDac7Countries,
      selected === 4 && requiredVatCountries,
    ].some(Boolean);
  };
  
  
  renderPaymentSection() {
    const { eprCategories, t } = this.props;
    const {
      chosenPlan,
      features,
      additionalCountries,
      sbsDateFrom,
      sbsDateTill,
      selected,
      sbsCountry,
      yearEPR,
    } = this.state;
    
    const datepickerProps = {
      showMonthYearPicker: true,
      showFullMonthYearPicker: true,
    };
    
    const renderDatepicker = (label, value, onChange) => (
      <Datepicker
        label={label}
        datepickerProps={datepickerProps}
        allowRange={false}
        customFormatDate={(date) => formatMonth(date)}
        value={value}
        start={value}
        onChange={onChange}
      />
    );
    
    const showDatepicker = selected !== 2;
    
    return (
      <Card
        sectioned
        title={<Text variant='headingLg'>Your subscription summary:</Text>}
      >
        <Text variant='bodyMd' fontWeight='medium'>
          {t("subscription.detailsPlan")}
        </Text>
        <br />
        <FormLayout>
          <FormLayout.Group condensed>
            <TextField
              label={t("subscription.plan")}
              disabled
              value={chosenPlan.tariff.name}
            />
            
            {selected === 2 && (
              <Select
                id='year'
                name='year'
                label={`Year:`}
                value={yearEPR.toString()}
                options={years.map((year) => ({
                  key: year,
                  label: year,
                  value: year.toString(),
                }))}
                onChange={(v) => this.setState({ yearEPR: v })}
              />
            )}
            
            {showDatepicker &&
              renderDatepicker(t("subscription.from"), sbsDateFrom, (date) => {
                const year = moment(date).year();
                const month = moment(date).month() + 1;
                
                this.setState({
                  sbsYearFrom: year,
                  sbsMonthFrom: month,
                  sbsDateFrom: date,
                });
              })}
            
            {showDatepicker &&
              renderDatepicker(t("subscription.till"), sbsDateTill, (date) => {
                const year = moment(date).year();
                const month = moment(date).month() + 1;
                this.setState({
                  sbsYearTill: year,
                  sbsMonthTill: month,
                  sbsDateTill: date,
                });
              })}
            
            {chosenPlan && chosenPlan.tariff.name === "PAN-EU" && (
              <TextField
                label={t("subscription.planPrices.countries")}
                value={
                  chosenPlan && chosenPlan.tariff.name !== "PAN-EU"
                    ? additionalCountries.map((country) => country)
                    : chosenPlan.tariff.included_countries.map(
                      (country) => country.code
                    )
                }
              />
            )}
            
            {chosenPlan && (chosenPlan.tariff.name !== "PAN-EU" && !chosenPlan.tariff.epr_plan_subscription )&& (
              <Select
                error={
                  !sbsCountry && t("subscription.required") && selected !== 1
                }
                id={"ctr_sps"}
                name={"ctr_sps_name"}
                disabled={selected === 1}
                label={selected === 1 ? "State" : t("subscription.country")}
                options={
                  selected === 1
                    ? [
                      {
                        key: "all",
                        label: "All",
                        value: "all",
                      },
                    ]
                    : this.getOptionsCountries(
                      chosenPlan.tariff.select_countries
                    )
                }
                value={selected === 1 ? "all" : this.state.sbsCountry}
                onChange={(v) =>
                  this.setState({
                    sbsCountry: v,
                    categoryEPR:
                      selected === 2 && _.find(eprCategories, { code: v })
                        ? _.find(eprCategories, {
                          code: v,
                        }).сategories[0].id.toString()
                        : "",
                  })
                }
              />
            )}
          </FormLayout.Group>
        </FormLayout>
        <br />
        
        {Object.keys(features).some((key) => features[key] !== 0) ? (
          <Text variant='bodyMd' fontWeight='medium'>
            {t("subscription.detailsAddons")}
          </Text>
        ) : (
          false
        )}
        <br />
        {this.renderAddonsSection()}
        <br />
        <TextField
          label={t("subscription.commentForOrder")}
          value={this.state.textComment}
          onChange={(value) => this.setState({ textComment: value })}
          multiline={5}
        />
        <br />
        <div>
          <Button
            variant={'primary'}
            disabled={this.getIsPaymentSectionButtonDisabled()}
            onClick={() => this.doPrepareDataForCalculate()}
            size='large'
	          >
	          {t("subscription.subs")}
	        </Button>
        </div>
      </Card>
    );
  }
  
  renderPlansColumns(plans, tdStyles) {
    const { chosenPlan } = this.state;
    const selectedId = chosenPlan ? chosenPlan.tariff.id : -1;
    
    return plans.map((tariff) => {
      const plansClassName =
        tariff.id === selectedId ? "tableRowColumnSelected" : "tableRowColumn";
      
      return <td className={plansClassName} style={tdStyles} />;
    });
  }
  
  renderSubscriptionCard(plans = []) {
    const { chosenPlan, currencyIcon, selected, billing_method } = this.state;
    const { fetchingSubPlans, subscriptionPlans, t } = this.props;
    
    const tableStyles = { width: "100%", borderSpacing: "0px" };
    const tdStyles = { borderBottom: 0 };
    
    return (
      <>
        {fetchingSubPlans && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </div>
        )}
        {!fetchingSubPlans && !_.isEmpty(subscriptionPlans) && (
          <div className='tableWrap'>
            <Plan
              plans={plans}
              chosenPlan={chosenPlan}
              currencyIcon={currencyIcon}
              selected={selected}
              billing_method={billing_method}
              handleSelectPlan={this.handleSelectPlan}
              resetSelectedPlan={this.resetSelectedPlan}
            />
            <table style={tableStyles}>
              <tr className='tableRow'>
                <td className='tableRowColumnLeft' style={tdStyles}>
                  <div style={{ padding: "1rem 0rem" }}>
                    <Text variant='headingLg'>{t("subscription.addons")}</Text>
                  </div>
                </td>
                {this.renderPlansColumns(plans)}
              </tr>
            </table>
            {this.renderFeatures(plans)}
          </div>
        )}
      </>
    );
  }
  
  renderEprSubscriptionCard() {
    const { chosenPlan, currencyIcon, selected, billing_method } = this.state;
    const { fetchingSubPlans, subscriptionPlans, t } = this.props;
    
    const tableStyles = { width: "100%", borderSpacing: "0px" };
    const tdStyles = { borderBottom: 0 };
    
    const eprSubscriptionPlans = subscriptionPlans
      ? subscriptionPlans[0]?.tariff_epr
      : {};
    
    const plans = eprSubscriptionPlans
      ? eprSubscriptionPlans.map((plan) => {
        return {
          ...plan,
          packaging: true,
          oneReportAutosubmit: true,
          reminders: true,
          monthly_price_when_billed_annually: plan.annual_price,
          epr_plan_subscription: true
        };
      })
      : [];
    return (
      <>
        {fetchingSubPlans && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </div>
        )}
        {!fetchingSubPlans && !_.isEmpty(subscriptionPlans) && (
          <div className='tableWrap'>
            <Plan
              plans={plans}
              chosenPlan={chosenPlan}
              currencyIcon={currencyIcon}
              selected={selected}
              billing_method={billing_method}
              handleSelectPlan={this.handleSelectPlan}
              resetSelectedPlan={this.resetSelectedPlan}
            />
            <table style={tableStyles}>
              <tr className='tableRow'>
                <td className='tableRowColumnLeft' style={tdStyles}>
                  <div style={{ padding: "1rem 0rem" }}>
                    <Text variant='headingLg'>{t("subscription.addons")}</Text>
                  </div>
                </td>
                {this.renderPlansColumns(plans, tdStyles)}
              </tr>
            </table>
            {this.renderFeatures(plans)}
          </div>
        )}
      </>
    );
  }
  
  renderCredits() {
    const { subscriptionPlans } = this.props;
    
    return (
      subscriptionPlans &&
      subscriptionPlans[0] &&
      subscriptionPlans[0].credits_bundles?.map((credit_bundle) => {
        const { t } = this.props;
        const currency = getCurrencySymbol(subscriptionPlans[0].currency);
        
        const showVerticalScroll = credit_bundle.type !== "sales_list";
        
        const isSalesTax = credit_bundle.type === "sales_tax";
        
        return (
          <div key={credit_bundle.id}>
            <Text variant='headingLg'>{credit_bundle.name}</Text>
            <br />
            <div
              className={classNames({
                "scrollable-container": showVerticalScroll,
              })}
            >
              <div
                className='creditsWrap'
                style={showVerticalScroll ? {} : { minWidth: "auto" }}
              >
                {credit_bundle.bundles.map((bundle, i) => (
                  <div
                    style={{ width: "100%", position: "relative" }}
                    key={bundle.id}
                  >
                    <Card sectioned>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: 2,
                          width: "100%",
                          padding: 10
                        }}
                      >
                        {((!isSalesTax && i === 1) ||
                          (isSalesTax && i === 3)) && (
                          <div className='mostPopular'>
                            {t("subscription.mostPopular")}
                          </div>
                        )}
                        <Text variant='headingMd' alignment='center'>
                          {currency}
                          {formatMoney(bundle.price)}
                        </Text>
                        <Text variant='heading2xl' alignment='center'>
                          {bundle.quantity}{" "}
                          {bundle.quantity > 1 ? "credits" : "credit"}
                        </Text>
                        <Text
                          variant='bodySm'
                          alignment='center'
                          color='subdued'
                        >
                          {bundle.name}{" "}
                        </Text>
                        {bundle.price_per_one ? (
                          <Text
                            variant='bodySm'
                            alignment='center'
                            color='subdued'
                          >
                            {currency} {bundle.price_per_one} per one return
                          </Text>
                        ) : null}
                        <Text
                          variant='bodyMd'
                          alignment='center'
                          color='success'
                          fontWeight='medium'
                        >
                          {bundle.discount ? (
                            `${bundle.discount}% discount`
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </Text>
                        <Button
                          variant={'primary'}
                          onClick={() =>
                            this.handleActionDialogsOpen("buyCredits", bundle)
                          }
                        >
                          Buy now
                        </Button>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </div>

            <br />
          </div>
        );
      })
    );
  }
  
  render() {
    const { fetchingSubPlans, subscriptionPlans, t } = this.props;
    const { selected, planType, billing_method } = this.state;
    
    const {
      tariff_epr,
      tariff_omp,
      tariff_plan,
      tariff_plan_sales_tax,
      credits_bundles,
    } = subscriptionPlans[0] ?? {};
    
    const tabs = [
      {
        id: "vat",
        content: "Vat Pricing",
        accessibilityLabel: "Vat Pricing",
        panelID: "vat-tab",
        condition: Boolean(tariff_plan),
      },
      {
        id: "sales",
        content: "Sales Tax Pricing",
        accessibilityLabel: "Sales Tax Pricing",
        panelID: "sales-tab",
        condition: Boolean(tariff_plan_sales_tax),
      },
      {
        id: "epr",
        content: "EPR Pricing",
        accessibilityLabel: "EPR Pricing",
        panelID: "epr-tab",
        condition: Boolean(tariff_epr),
      },
      {
        id: "credits",
        content: "Credits",
        accessibilityLabel: "Credits",
        panelID: "credits",
        condition: Boolean(credits_bundles),
      },
      {
        id: "omp",
        content: "OMP Pricing",
        accessibilityLabel: "OMP Pricing",
        panelID: "omp",
        condition: Boolean(tariff_omp),
      },
    ];
    
    const filteredTabs = tabs.filter((tab) => tab.condition);
    
    return (
      <Page
        fullWidth
        title={
          <GoBackPageNavigation content={
            <Text variant='heading3xl' as='span'>
             {t("subscription.subPlans")}
            </Text>
          } />
        }
        primaryAction={
          selected < 2 && (
            <ButtonGroup segmented>
              <Button
                variant={billing_method === BillingMethods.monthly? 'primary': ''}
                onClick={() =>
                  this.setState({ billing_method: BillingMethods.monthly })
                }
              >
                {t("subscription.monthly")}
              </Button>
              <Button
                variant={billing_method === BillingMethods.annually? 'primary': ''}
                onClick={() =>
                  this.setState({ billing_method: BillingMethods.annually })
                }
              >
                {t("subscription.annually")} (-15%)
              </Button>
            </ButtonGroup>
          )
        }
      >
        <PageHelmet title={"Subscription"} />

        <Tabs
          tabs={filteredTabs}
          selected={filteredTabs.findIndex(
            (tab) => tab.id === tabs[selected].id
          )}
          onSelect={(tabIndex) => {
            this.setState((prevState) => {
              const selected = tabs.findIndex(
                (tab) => tab.id === filteredTabs[tabIndex].id
              );
              const defaultBillingMethod =
                selected === 2
                  ? BillingMethods.annually
                  : prevState.billing_method;
              
              return {
                selected,
                billing_method: defaultBillingMethod,
                yearEPR: years[1],
              };
            });
            this.resetSelectedPlan();
          }}
        />
        <br />
        {selected === 0 && (
          <>
            <Card sectioned={fetchingSubPlans}>
              {this.renderSubscriptionCard(
                planType ? planType : subscriptionPlans[0]?.tariff_plan || []
              )}
            </Card>
            {this.renderAdditionalCountries()}
            {this.state.chosenPlan && this.renderPaymentSection()}
          </>
        )}
        
        {selected === 1 && (
          <>
            <Card sectioned={fetchingSubPlans}>
              {this.renderSubscriptionCard(
                planType
                  ? planType
                  : subscriptionPlans[0]?.tariff_plan_sales_tax || []
              )}
            </Card>
            {this.state.chosenPlan && this.renderPaymentSection()}
          </>
        )}
        
        {selected === 2 && (
          <>
            <Card sectioned={fetchingSubPlans}>
              {this.renderEprSubscriptionCard()}
            </Card>
            {this.state.chosenPlan && this.renderPaymentSection()}
          </>
        )}
        {selected === 3 && (
          <>
            {fetchingSubPlans ? (
              <Card sectioned>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spinner />
                </div>
              </Card>
            ) : (
              this.renderCredits()
            )}
          </>
        )}
        
        {selected === 4 && (
          <>
            <Card sectioned={fetchingSubPlans}>
              {this.renderSubscriptionCard(
                planType ? planType : subscriptionPlans[0]?.tariff_omp || []
              )}
            </Card>
            {this.state.chosenPlan && this.renderPaymentSection()}
          </>
        )}
        
        {this.renderSubscribeDialog()}
        {this.renderNotificationDialog()}
        {this.renderBuyCredits()}
        <br />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  orderingError: state.payment.orderingError,
  ordering: state.payment.ordering,
  fetchingSubPlans: state.subscription.fetchingSubPlans,
  subscriptionPlans: state.subscription.subscriptionPlans,
  countries: state.other.countries,
  defaultLanguage: state.user.defaultLanguage,
  banksList: state.manager.banksList,
  eprCategories: state.subscription.eprCategories,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);
  
  return {
    orderPayment: (data) => dispatch(orderPayment(data)),
    checkPromoCode: (data) => dispatch(checkPromoCode(data)),
    fetchCalculateSubscription: (data) =>
      dispatch(fetchCalculateSubscription(data)),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchSubscriptionPlans: () => dispatch(fetchSubscriptionPlans()),
    fetchBanksList: () => dispatch(fetchBanksList()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Subscription))
);
